import React from 'react';
import {Row, Col, Image} from 'react-bootstrap'
import companyhead from '../assets/img/companyprofile.jpg'


const Companyprofilepage = () => {
    return(
        <div>
        <Row>
            <Col xs={12} md={12} className="mt-4 mb-5">
                <h1>Company Profile</h1>
            </Col>
            
             <Col xs={12} md={12}>
            <p>Founded in 1962, as Paragon Cables, to engineer and manufacture advanced and safe cables and wires, today as a group, Elektron spans across multiple verticals with a nationwide presence. We have been entrusted with a reputation, built on a culture of quality, innovation and uncompromising service. We are one of India’s leading innovators in the field of cables and electrical product design and manufacturing. We are at our core a group of engineers and technologists, with a passion for problem solving. We envision a future, fuelled by, a relentless dedication to improving lives through sustainable growth and community driven growth.  </p>
            </Col>

            <Col xs={12} md={12}>
            <Image src={companyhead} alt="Card image" fluid />
            </Col>
        </Row>
       
         
           
           
     </div>
    )
}

export default Companyprofilepage;