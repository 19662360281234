import React from 'react';
import {Row, Col, Image} from 'react-bootstrap'
import iot1 from '../assets/img/iot1.png'
import production from '../assets/img/production.png'

const Experiencepage = () => {
    return(
        <div>
        <Row>
        
            <Col xs={12} md={12} className="mt-5 mb-4">
            <h1>360° Experience</h1>
            </Col>
             <Col xs={12} md={10}>
            <h3 className="blue-themetext">From Vision to Reality</h3>
            <p className="mt-4">Our team of experts is able to deliver a 360 degrees experience to our customers. From Concept and Design right till Manufacturing and Certification, we have all ODM requirements covered. Our expertise in manufacturing and engineering helps us design and deliver precise, and assured results. Our team is passionate about solving big problems with smart tech. Our products are empowered with expertly tailor made cloud and software solutions. </p>
            </Col>
            
        </Row>
        <h3 className="blue-themetext mt-5">End-to-End Solution</h3>
        <Row className="text-center">
           <Col xs={12} md={{span:10,offset:1}}>
           <Image src={iot1} alt="Card image" className="mb-5 mt-4" fluid/>
           </Col>
         </Row>
         <h3 className="blue-themetext mt-5 mb-5">Concept to Production</h3>
        <Row className="text-center">
           <Col>
           <Image src={production} alt="Card image" className="mb-4 mt-4" fluid/>
           </Col>
         </Row>

        
           
     </div>
    )
}

export default Experiencepage;