import React from 'react';
import Homeslider from '../components/Homeslider';
import Hometext from '../components/Hometext';
import Homegridsingle from '../components/Homegridsingle';
// import Homecasestudy from '../components/Homecasestudy';
import Clientslider from '../components/Clientslider';
import Container from 'react-bootstrap/Container'
import Homemarketexpertise from '../components/Homemarketexpertise'

function Home() {
  return (
    <Container>
      <Homeslider />
      <Hometext />
      <Homegridsingle />
      {/* <Homecasestudy /> */}
      <Homemarketexpertise />
      <Clientslider/>
      </Container>
  );
}


export default Home;