import React from 'react';
import {Row, Col, Image} from 'react-bootstrap'
import tab1 from '../assets/img/tab1.svg';
import tab2 from '../assets/img/tab2.svg';
import tab3 from '../assets/img/tab3.svg';
import tab4 from '../assets/img/tab4.svg';
import tab5 from '../assets/img/tab5.svg';


const Qualityprefooter = () => {
    return(

        <div>
        <div id="" className=" blue-themebg text-white mt-5">
        <Row className="blue-themebg text-white m-0 pt-4 pb-4 text-center">
           <Col>
           <Image src={tab5} alt="Card image" className="mb-4 mt-4" fluid/>
            <p>Quality Testing Labs</p>
           </Col>
           <Col>
           <Image src={tab2} alt="Card image" className="mb-4 mt-4" fluid/>
               <p>Robust Technical Team & Support</p>
           </Col>
           <Col>
           <Image src={tab3} alt="Card image" className="mb-4 mt-4" fluid/>
               <p>Agile R&D Approach</p>
           </Col>
           <Col>
           <Image src={tab4} alt="Card image" className="mb-4 mt-4" fluid/>
               <p>International & Domestic Compliances <br/><span>150+ Certificates & Approvals</span></p>
               
           </Col>
           <Col>
           <Image src={tab1} alt="Card image" className="mb-4 mt-4" fluid/>
               <p>Quality Guarantee</p>
           </Col>
                
         </Row>
         </div>
         </div>
    )
      }

      export default Qualityprefooter;