import React from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Image, Button} from 'react-bootstrap'
import iot from '../assets/img/iot.jpg'
import iot1 from '../assets/img/iot1.png'
import iot2 from '../assets/img/iot2.svg'
import iot3 from '../assets/img/iot3.svg'
import iot4 from '../assets/img/iot4.svg'
import iot5 from '../assets/img/iot5.svg'
import iot6 from '../assets/img/iot6.svg'
import iot7 from '../assets/img/iot7.svg'
import iot8 from '../assets/img/iot8.svg'
import iot9 from '../assets/img/iot9.svg'
import ind1 from '../assets/img/ind1.svg'
import ind2 from '../assets/img/ind2.svg'
import ind3 from '../assets/img/ind3.svg'
import ind4 from '../assets/img/ind4.svg'
import ind5 from '../assets/img/ind5.svg'
import ind6 from '../assets/img/ind6.svg'

const Iotpage = () => {
    return(
        <div>
        <Row>
            <Col xs={12} md={12} className="mt-4 mb-4">
            <h2>Internet of Things</h2>
            </Col>
             <Col xs={12} md={7}>
            <h3 className="blue-themetext">Envision A Smarter Future</h3>
            <p className="mt-4">The only way to predict the future is to create it. We at Elektron, are passionate about solving big problems with innovation and technology. With our expertise in engineering, manufacturing, design and integration, we are able to deliver cutting edge solutions harnessing IoT networks (M2M GSM, NB IOT, RF, Zigbee and many others). At our core, we are a group of technologists driven by growth and disruption. We can customize solutions for diverse industries, fields, operations and problems. </p>
            </Col>
            <Col xs={12} md={5}>
            <Image src={iot} alt="Card image" fluid />
            </Col>
        </Row>
        <h3 className="blue-themetext mt-5">End-to-End Solution</h3>
        <Row className="text-center">
           <Col  xs={12} md={{span:10,offset:1}}>
           <Image src={iot1} alt="Card image" className="mb-4 mt-4" fluid/>
           </Col>
         </Row>
          <h3 className="blue-themetext mt-5 mb-5">What We Do</h3>
            <Row  className="mb-4 mt-4">
            <Col xs={12} md={6} >
                <Row>
                    <Col xs={4} md={4} >
                    <Image src={iot2} alt="Card image" className="mb-4 mt-4" fluid/>
                    </Col>
                    <Col xs={8} md={8} >
                    <h4 className="blue-themetext">Product Engineering & Design</h4>
                    <p>PCB Design & Layout<br/>
                        Low Power Design<br/>
                        Complex PCB Design<br/>
                        RF, Wireless Engineering<br/>
                        3D Modelling<br/>
                        Industrial and Ruggedized Design</p>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} md={6} >
            <Row>
                    <Col xs={4} md={4} >
                    <Image src={iot5} alt="Card image" className="mb-4 mt-4" fluid/>
                    </Col>
                    <Col xs={8} md={8} >
                    <h4 className="blue-themetext">Manufacturing & Mechanial
Engineering</h4>
                    <p>PCB Assembly<br/>
                        3D Printers<br/>
                        Wave Soldering<br/>
                        Surface Mount Technology (SMT)<br/>
                        Injection Moulding <br/>
                        Wires Harness <br/>
                        Wires & Cables<br/>
                        Sheet Metal Fabrication<br/>
                        Panels<br/>
                        Extrusion <br/>
                        Product Assembly<br/>
                        Tool Rooms
                        </p>
                    </Col>
            </Row>
            </Col>
            </Row>
            <Row  className="mb-4 mt-4">
            <Col xs={12} md={6} >
                <Row>
                    <Col xs={4} md={4} >
                    <Image src={iot8} alt="Card image" className="mb-4 mt-4" fluid/>
                    </Col>
                    <Col xs={8} md={8} >
                    <h4 className="blue-themetext">Embedded Software </h4>
                    <p>Firmware, Middleware<br/>
                        Device Driver<br/>
                        Embedded Application<br/>
                        BSP<br/>
                        OS Porting & Customization</p>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} md={6} >
            <Row>
                    <Col xs={4} md={4} >
                    <Image src={iot6} alt="Card image" className="mb-4 mt-4" fluid/>
                    </Col>
                    <Col xs={8} md={8} >
                    <h4 className="blue-themetext">Wireless Experitse</h4>
                    <p>Wi-Fi<br/>
                        NBIoT, LTE<br/>
                        ZigBee<br/>
                        BLE 4.0,4.2,5.0<br/>
                        NFC, Bluetooth<br/>
                        2G, 3G- GPS/GSM/<br/>
                        GPRS/WCDMA</p>
                    </Col>
            </Row>
            </Col>
            </Row>
            <Row  className="mb-4 mt-4">
            <Col xs={12} md={6} >
                <Row>
                    <Col xs={4} md={4} >
                    <Image src={iot4} alt="Card image" className="mb-4 mt-4" fluid/>
                    </Col>
                    <Col xs={8} md={8} >
                    <h4 className="blue-themetext">Sensors Expertise</h4>
                    <p>Sensor Integration<br/>
Single or Multi-Camera Sensor<br/>
Humidity, Temperature & Pressure<br/>
Moisture Detection & Glass Break<br/>
Tamper/Ambient & Shock Detection</p>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} md={6} >
            <Row>
                    <Col xs={4} md={4} >
                    <Image src={iot7} alt="Card image" className="mb-4 mt-4" fluid/>
                    </Col>
                    <Col xs={8} md={8} >
                    <h4 className="blue-themetext">AI & Analytics Expertise</h4>
                    <p>AI/ML on Edge<br/>
                    Neural Networks & Deep Learning<br/>
                    Machine Vision & Tensor Flow<br/>
                    Big Data Analytics</p>
                    </Col>
            </Row>
            </Col>
            </Row>
            <Row className="mb-4 mt-4">
            <Col xs={12} md={6} >
                <Row>
                    <Col xs={4} md={4} >
                    <Image src={iot3} alt="Card image" className="mb-4 mt-4" fluid/>
                    </Col>
                    <Col xs={8} md={8} >
                    <h4 className="blue-themetext">Cloud, Platform & App Development</h4>
                    <p>Sensors Expertise<br/>
Cloud Operation, Migration & Analytics<br/>
Cloud Infrastructure & Implementation<br/>
Cloud Security<br/>
Multi Screen Platform & App Development<br/>
UX/UI Design<br/>
System Apps<br/>
Sensor Integration<br/>
Single or Multi-Camera Sensor<br/>
Humidity, Temperature & Pressure<br/>
Moisture Detection & Glass Break<br/>
Tamper/Ambient & Shock Detection<br/>
</p>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} md={6} >
            <Row>
                    <Col xs={4} md={4} >
                    <Image src={iot9} alt="Card image" className="mb-4 mt-4" fluid/>
                    </Col>
                    <Col xs={8} md={8} >
                    <h4 className="blue-themetext">QA & Testing</h4>
                    <p>Agile<br/>
Testing and Validation<br/>
Interoperability Testing<br/>
QA Automation<br/>
Certification</p>
                    </Col>
            </Row>
            </Col>
            </Row>
            <h3 className="blue-themetext mt-5 mb-5">Industries</h3>
            <Row  className="text-center mb-4 mt-4">
            <Col xs={6} md={4} >
            <Image src={ind1} alt="Card image" className=" mt-4" fluid/>
               <p className="blue-themetext">Agriculture</p>
            </Col>
            <Col xs={6} md={4} >
            <Image src={ind2} alt="Card image" className=" mt-4" fluid/>
               <p className="blue-themetext">Security</p>
            </Col>
            <Col xs={6} md={4} >
            <Image src={ind3} alt="Card image" className=" mt-4" fluid/>
               <p className="blue-themetext">Electric Vehicles</p>
            </Col>
            <Col xs={6} md={4} >
            <Image src={ind4} alt="Card image" className=" mt-4" fluid/>
               <p className="blue-themetext">Healthcare</p>
            </Col>
            <Col xs={6} md={4} >
            <Image src={ind5} alt="Card image" className=" mt-4" fluid/>
               <p className="blue-themetext">Smart Home</p>
            </Col>
            <Col xs={6} md={4} >
            <Image src={ind6} alt="Card image" className=" mt-4" fluid/>
               <p className="blue-themetext">Manufacturing</p>
            </Col>
            </Row>
            <Row  className="text-center mt-5 mb-5">
            <Col>
            <h5>Learn more about our process  <Button className="button m-2"><Link to="360-Experience">360° Experience</Link></Button></h5>
            </Col>
            </Row>
           
     </div>
    )
}

export default Iotpage;