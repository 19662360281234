import React from 'react';
import Companyprofilepage from '../components/Companyprofilepage';
import Companyprefooter from '../components/Companyprefooter';
import Container from 'react-bootstrap/Container'

function Companyprofile() {
  return (
      <div>
    <Container>
      <Companyprofilepage />
      </Container>
       <Companyprefooter />
    </div>
  );
}

export default Companyprofile;