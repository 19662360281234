import React from 'react';
import {Row, Col, Image, Button} from 'react-bootstrap'
import epc1 from '../assets/img/epc1.svg'
import epc2 from '../assets/img/epc2.svg'
import epc3 from '../assets/img/epc3.svg'
import en1 from '../assets/img/en1.svg'
import en2 from '../assets/img/en2.svg'
import en3 from '../assets/img/en3.svg'

import {Link} from 'react-router-dom';

const Energypageprefooter = () => {
    return(
        <div>
           <Row>
            <Col xs={12} md={12} className="text-center mt-4 mb-4">
            <Button className="button"><Link to="Lighting">SEE OUR LIGHTING SOLUTIONS</Link></Button>
            </Col>
             <Col xs={12} md={12}>
            <h4 className="mt-4">EPC</h4>
            </Col>
            </Row>
            <Row>
            <Col xs={12} md={8}>
            <p className="mt-4">Elektron is an experienced Engineering and Construction (EPC) solutions provider in the area of Smart Lighting, Traffic and City Management. We work with the Government and the corresponding Urban Local Bodies (ULB) to provide complete street lighting infrastructure and maintenance on Turney basis. Elektron is providing EPC solutions with Build-Operate-Transfer model, across India. The Organization has good experience in execution and has a track record of delivering the projects with superior quality within the time schedule under all kinds of environments. With maintenance contracts of 8+ years, we have proven the quality and longevity of our products and services. We have urban designers and planners in our team to ensure that we make a positive impact on the city and its people. Through extensive stakeholder engagement programmes, we work with the city to truly engage the community through in-person talks and our digital platform and app.  </p>
            </Col>
            
            <Col xs={12} md={12} className="mt-4">
            <h5 className="blue-themetext">BOT - Build Operate Transfer</h5>
            </Col>
            </Row>
            <Row className="text-center mt-4 mb-5">

                <Col  xs={12} md={4}>
                <Image src={epc1} alt="Card image" className="mt-4" fluid/>
                <p className="blue-themetext">Build</p>
                </Col>
                <Col  xs={12} md={4}>
                <Image src={epc2} alt="Card image" className="mt-4" fluid/>
                <p className="blue-themetext">Operate</p>
                </Col>
                <Col  xs={12} md={4}>
                <Image src={epc3} alt="Card image" className="mt-4" fluid/>
                <p className="blue-themetext">Transfer</p>
                </Col>
                <Col xs={12} md={12} className="text-center mt-4 mb-4">
            <Button className="button"><Link to="EPC">SEE OUR EPC SOLUTIONS</Link></Button>
            </Col>
                </Row>

                <Row>
             <Col xs={12} md={12}>
            <h4 className="mt-4">Energy Efficiency Solutions</h4>
            </Col>
            </Row>
            <Row>
            <Col xs={12} md={8}>
            <p className="mt-4">Elektron is an experienced Engineering and Construction (EPC) solutions provider in the area of Smart Lighting, Traffic and City Management. We work with the Government and the corresponding Urban Local Bodies (ULB) to provide complete street lighting infrastructure and maintenance on Turney basis. Elektron is providing EPC solutions with Build-Operate-Transfer model, across India. The Organization has good experience in execution and has a track record of delivering the projects with superior quality within the time schedule under all kinds of environments. With maintenance contracts of 8+ years, we have proven the quality and longevity of our products and services. We have urban designers and planners in our team to ensure that we make a positive impact on the city and its people. Through extensive stakeholder engagement programmes, we work with the city to truly engage the community through in-person talks and our digital platform and app.  </p>
            </Col>
            
            <Col xs={12} md={12} className="mt-4">
            <h5 className="blue-themetext">Save and Optimize</h5>
            </Col>
            </Row>
            <Row className="text-center mt-4 mb-5">

                <Col  xs={12} md={4}>
                <Image src={en1} alt="Card image" className="mt-4" fluid/>
                <p className="blue-themetext">Energy</p>
                </Col>
                <Col  xs={12} md={4}>
                <Image src={en2} alt="Card image" className="mt-4" fluid/>
                <p className="blue-themetext">Water</p>
                </Col>
                <Col  xs={12} md={4}>
                <Image src={en3} alt="Card image" className="mt-4" fluid/>
                <p className="blue-themetext">Time</p>
                </Col>
                <Col xs={12} md={12} className="text-center mt-4 mb-4">
            <Button className="button"><Link to="Energy-Efficiency">SEE OUR ENERGY EFFICIENCY SOLUTIONS</Link></Button>
            </Col>
                </Row>
           
           
     </div>
    )
}

export default Energypageprefooter;