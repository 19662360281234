import React from 'react';
import Container from 'react-bootstrap/Container'
import Quality from '../components/Quality';
import Qualityprefooter from '../components/Qualityprefooter';

function Qualityassurance() {
  return (
      <div>
    <Container>
      <Quality />
      </Container>
       <Qualityprefooter />
    </div>
  );
}

export default Qualityassurance;