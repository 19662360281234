import React from 'react';
import {Row, Col, Image} from 'react-bootstrap'
import makein from '../assets/img/makeinindia.png'
import homeg1 from '../assets/img/homeg1.svg'
import homeg2 from '../assets/img/homeg2.svg'
import homeg3 from '../assets/img/homeg3.svg'

const Homemarketexpertise = () => {
    return(
        <div>
        <Row>
             <Col xs={12} md={12}>
            <h2 className="blue-themetext mb-5">Market Expertise</h2>
            </Col>
            <Col xs={12} md={4} className="text-center p-4">
            <Image src={homeg1} alt="Card image" fluid />
            <h5 className="blue-themetext mt-4">Government</h5>
            </Col>
            <Col xs={12} md={4} className="text-center p-4">
            <Image src={homeg2} alt="Card image" fluid />
            <h5 className="blue-themetext mt-4">Industries</h5>
            </Col>
            <Col xs={12} md={4} className="text-center p-4">
            <Image src={homeg3} alt="Card image" fluid />
            <h5 className="blue-themetext mt-4">Consumer</h5>
            </Col>
        </Row>
         <Row className="align-items-center">
         <Col xs={12} md={12}>
        <h2 className="blue-themetext mt-5">Innovation Localized</h2>
        </Col>
        <Col xs={12} md={6}>
          <p >As a group of passionate technologists, with operations spread across India, in Delhi, Haryana and Aurangabad, we at Elektron, strive to bring innovative and sustainable practices to complex local environments. </p>
        </Col>
        <Col xs={12} md={6}>
            <Image src={makein} alt="Card image" fluid />
         </Col>
    </Row>
    </div>
    )
}

export default Homemarketexpertise;