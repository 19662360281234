import React from 'react';
import {Row, Col, Image, Container} from 'react-bootstrap'
import cp from '../assets/img/cp.png'


const Companyprefooter = () => {
    return(

        <div>
        <div id="" className=" blue-themebg text-white mt-5 mb-4">
            <Container>
        <Row className="blue-themebg text-white m-0 pt-4 pb-4 ">
           <Col  xs={12} md={3}>
           <Image src={cp} alt="Card image" className="mb-4 mt-4" fluid/>
           </Col>
           <Col  xs={12} md={9} className="align-self-center">
              <h5>Innovate-Develop-Manufacture-Deploy Products & Services to manage precious resources by effective use of technology designed to conserve Energy, Water & Time through resolving the needs of the Citizens & Cities of our amazing Country.

</h5>
           </Col>
           
         </Row>
         </Container>
         </div>
         </div>
    )
      }

      export default Companyprefooter;