import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Card, Form, Button,Image} from 'react-bootstrap'
import './Contactpage.css'
import axios from 'axios';
import mail from '../assets/img/mail.svg'
import call from '../assets/img/call.svg'


class Contactpage extends Component{

    constructor (props) {
        super(props);
        this.initialstate = {  fullname: "",
                        email: "",
                        purpose: "",
                        message: "",sent:0,buttonText:'Submit'
                    }
         this.state = this.initialstate;
    }

    formhandle = (event) => {
        this.setState({[event.target.name] :event.target.value });
        // console.log(event.target.value);
    }

    handlesubmit = (event) => {
                        event.preventDefault()

                this.setState({
                    buttonText: '...sending'
                })
                // let btnElement = this.document.getElementById('submitbtn');
                // if(typeof btnElement!=undefined &&  btnElement!=null &&  btnElement!=undefined) {
                //    this.document.getElementById('submitbtn').disabled=true
                // }
                // let data = {
                //     fullname: this.state.fullname,
                //     email: this.state.email,
                //     purpose: this.state.purpose,
                //     message: this.state.message
                // }
                
                axios.post('/api/contactus', this.state)
                .then( res => {
                    console.log(res.data.status)
                    let sentflag = (res.data.status=='Success') ? 1 : 0;
                    this.setState({ sent: sentflag})
                })
                .catch( () => {
                    console.log('Message not sent')
                })

        // alert (JSON.stringify(this.state));
        // event.preventDefault();
        // this.setState(this.initialState);
    }
    // resetForm = () => {
    //     this.setState({
    //         fullname: '',
    //         email: '',
    //         purpose: '',
    //         message: '',
    //         // buttonText: 'Message Sent'
    //     })
    // }
    render(){
        return(
            <div>
            <Row>
                <Col xs={12} md={12} className="mt-5 mb-5">
                <h1>Get in Touch</h1>
                </Col>
                </Row>
            <Row id="contact" className="mb-4">
                 <Col xs={12} md={6}>
                 <Card body>
                { this.state.sent==0 ?
                 <Form onSubmit={this.handlesubmit}>
                 <Form.Group controlId="">
                    <Form.Control
                        required
                        type="text"
                        placeholder="First name"
                        name="fullname"
                        value={this.state.fullname}
                        onChange={this.formhandle}/>
                    </Form.Group>
    
                    <Form.Group controlId="">
                        <Form.Control required type="email"  placeholder="Enter email" name="email" value={this.state.email} onChange={this.formhandle} />
                    </Form.Group>
                    
                    <Form.Group controlId="">
                        {/* <Form.Label>Purpose</Form.Label> */}
                        <Form.Control as="select" custom name="purpose" value={this.state.purpose} onChange={this.formhandle}>
                        <option value="">Purpose</option>
                        <option value="Commercial Query">Commercial Query</option>
                        <option value="Technical Support/Complaint">Technical Support/Complaint</option>
                        {/* <option value="4">4</option>
                        <option value="5">5</option> */}
                        </Form.Control>
                    </Form.Group>
    
                    <Form.Group controlId="">
                        <Form.Control type="text" placeholder="Message" name="message" value={this.state.message} 
                        onChange={this.formhandle} />
                    </Form.Group>
    
                    <Button className="button" type="submit" value="send" id="submitbtn">
                        {this.state.buttonText}
                    </Button>
                    </Form>
                 : <h4>Thank you! <br/> Your enquiry has been submitted.</h4> }
                 
                 </Card>
                </Col>
    
                <Col xs={12} md={6} className="p-5">
                 <p>For careers and internships, please <Link to="Career" className="blue-themetext un">click here.</Link></p>
    
                 <p>To reach us directly -</p>
                 <Row>
                 <Col xs={2} md={2} className="">
                 <Image src={mail} alt="Card image" className="" fluid />
                 </Col>
                 <Col xs={10} md={10} className="">
                 <p className="blue-themetext">info@elektron.com</p>
                </Col>
                </Row>
                <Row>
                 <Col className="">
                 <Image src={call} alt="Card image" className="" fluid />
                 </Col>
                 <Col xs={10} md={10} className="">
                <p className="blue-themetext"> +91-11-27691333<br/>+91-11-27692333</p>
                </Col>
                </Row>
                </Col>
            </Row>
            
         </div>
        )
    }
}


export default Contactpage;