import React from 'react';
import {Row, Col, Image, Button, Breadcrumb} from 'react-bootstrap'
import {Link} from 'react-router-dom';
import epc1 from '../assets/img/epc1.svg'
import epc from '../assets/img/epc.png'
import epc2 from '../assets/img/epc2.svg'
import epc3 from '../assets/img/epc3.svg'
import epc4 from '../assets/img/epc4.svg'
import epc5 from '../assets/img/epc5.svg'
import epc6 from '../assets/img/epc6.svg'
import epcb from '../assets/img/epcb.png'
import epcb1 from '../assets/img/epcb1.jpg'
import epcb2 from '../assets/img/epcb2.jpg'

const Experiencepage = () => {
    return(
        <div>
        <Row>
        
            <Col xs={12} md={12} className="mt-5 mb-4">
            <h2>EPC</h2>
            </Col>
             <Col xs={12} md={10}>
            <h3 className="blue-themetext">Impact Driven Performance</h3>
           
            </Col>
            
        </Row>
        <h4  className="blue-themetext mt-4">BOT - Build Operate Transfer</h4>
       
        <Row className="text-center mt-5">
           <Col  xs={12} md={4}>
           <Image src={epc1} alt="Card image" className="mb-4 mt-4" fluid/>
           <p className="blue-themetext">Build</p>
           </Col>
           <Col  xs={12} md={4}>
           <Image src={epc2} alt="Card image" className="mb-4 mt-4" fluid/>
           <p className="blue-themetext">Operate</p>
           </Col>
           <Col  xs={12} md={4}>
           <Image src={epc3} alt="Card image" className="mb-4 mt-4" fluid/>
           <p className="blue-themetext">Transfer</p>
           </Col>
          
         </Row>

         <Row className="mt-5">
           <Col  xs={12} md={12}>
               <p>Elektron is an experienced Engineering and Construction (EPC) solutions provider in the area of Smart Lighting, Traffic and City Management. We work with the Government and the corresponding Urban Local Bodies (ULB) to provide complete street lighting infrastructure and maintenance on Turney basis. Elektron is providing EPC solutions with Build-Operate-Transfer model, across India. The Organization has good experience in execution and has a track record of delivering the projects with superior quality within the time schedule under all kinds of environments. With maintenance contracts of 8+ years, we have proven the quality and longevity of our products and services. We have urban designers and planners in our team to ensure that we make a positive impact on the city and its people. Through extensive stakeholder engagement programmes, we work with the city to truly engage the community through in-person talks and our digital platform and app. 
                </p>
           </Col>
           </Row>
           <Row className="mt-5">
           <Col  xs={12} md={12}>
           <h3 className="blue-themetext">Engineering & Procurement</h3>
           </Col>
           <Col  xs={12} md={12}>
           <Image src={epc} alt="Card image" className="mb-4 mt-4" fluid/>
           </Col>
           <Col  xs={12} md={12}>
           <h3 className="blue-themetext mt-5">Guiding Principles</h3>
           </Col>
           </Row>
           <Row className="text-center mt-5">
           <Col  xs={12} md={4}>
           <Image src={epc4} alt="Card image" className="mb-4 mt-4" fluid/>
           <p className="blue-themetext">Sustainable</p>
           </Col>
           <Col  xs={12} md={4}>
           <Image src={epc5} alt="Card image" className="mb-4 mt-4" fluid/>
           <p className="blue-themetext">Localized</p>
           </Col>
           <Col  xs={12} md={4}>
           <Image src={epc6} alt="Card image" className="mb-4 mt-4" fluid/>
           <p className="blue-themetext">Impact Driven</p>
           </Col>
          
           </Row>
           <Row>
        
                <Col xs={12} md={12} className="mt-5 mb-4">
                <h2>Featured Project</h2>
                </Col>
                <Col xs={12} md={10}>
                <h3 className="blue-themetext">Aurangabad Municipal Corporation, Maharashtra</h3>
            
                </Col>
        
                </Row>
                <Row className="mt-5">
                <Col  xs={12} md={12}>
                    <p>We designed, built and continue to operate and maintain the street lighting infrastructure for the city of Aurangabad. The goal was to provide the most energy efficient solution for streelighting to remove any dark zones and provide uniform illumination to make it smarter, safer and more sustainable; along with upgrading the city ageing electrical infrastructure. The LED Street Lights were installed as per National Lighting Code illumination specified for each type of road based on the traffic & importance. The lighting fixtures & illumination designs were chosen to make it possible for the City to use minimum energy for optimum illumination. Each Lighting asset is GPS marked & web based. We deployed custom-designed IoT sensors and controllers to suit the project and city needs. A citywide dashboard and platform is used to see and manage the whole city. Residents can use our Citizen App to report any issues, which are generally resolved within 24 hours by our support team and engineers spread across 9 control centers in the city. A dedicated call center helps us remain on top of all concerns.
 </p>
                </Col>
                </Row>
                <Row className="text-center">
                <Col xs={12} md={2} className="mt-5 mb-4">
                <h2 className="blue-themetext">40000</h2>
                <p>Street Lights</p>
                </Col>
                <Col xs={12} md={2} className="mt-5 mb-4">
                <h2 className="blue-themetext">30000</h2>
                <p>Poles</p>
                </Col>
                <Col xs={12} md={2} className="mt-5 mb-4">
                <h2 className="blue-themetext">1200</h2>
                <p>Control Panels</p>
                </Col>
                <Col xs={12} md={2} className="mt-5 mb-4">
                <h2 className="blue-themetext">1500+</h2>
                <p>Kilometers of Roads Impacted</p>
                </Col>
                <Col xs={12} md={2} className="mt-5 mb-4">
                <h2 className="blue-themetext">9</h2>
                <p>Control Centers Setup</p>
                </Col>
                <Col xs={12} md={2} className="mt-5 mb-4">
                <h2 className="blue-themetext">1</h2>
                <p>Call Center</p>
                </Col>
                
                 </Row>
                 <Row className="text-center mt-4 mb-5">
                    <Col  xs={12} md={12}>
                    <Image src={epcb} alt="Card image" className="mb-4 mt-4" fluid/>
                    </Col>
                    <Col  xs={12} md={6}>
                    <Image src={epcb1} alt="Card image" className="mb-4 mt-4" fluid/>
                    </Col>
                    <Col  xs={12} md={6}>
                    <Image src={epcb2} alt="Card image" className="mb-4 mt-4" fluid/>
                    </Col>
                    <Col  xs={12} md={12} className="text-center ">
                    <Button className="button m-2"><Link to="clients">VIEW ALL OUR CLIENTS</Link></Button>
                    </Col>
          
                 </Row>
           
     </div>
    )
}

export default Experiencepage;