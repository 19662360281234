import React from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Image, Button} from 'react-bootstrap'
import linkedin from '../assets/img/linkedin.svg'
import team from '../assets/img/team/vikas-nagpal.png'
import team1 from '../assets/img/team/ss-shukla.png'
import team2 from '../assets/img/team/pr-bhansode.png'
import team3 from '../assets/img/team/mukul-jain.png'
import team4 from '../assets/img/team/prakash-pandey.png'
import team5 from '../assets/img/team/hc-mishra.png'
import team6 from '../assets/img/team/parveen-bansal.png'
import team7 from '../assets/img/team/arjun-singh.png'
import team8 from '../assets/img/team/tanvi-nagpal.png'
import team9 from '../assets/img/team/cp-verma.png'
import team10 from '../assets/img/team/dinesh.png'
import team11 from '../assets/img/team/nikhil.png'
import team12 from '../assets/img/team/koundinya.png'

const Teampage = () => {
    return(
        <div>
        <Row>
            <Col xs={12} md={12} className="mt-4 mb-5">
                <h1>Key People</h1>
            </Col>
            <Col xs={12} md={4}>
            <Image src={team} alt="Card image" fluid />
            <h5  className="mt-4">Vikas Nagpal</h5>
            <p><span>Managing Director</span></p>
           <a href="http://linkedin.com/in/vikas-nagpal-62470852" target="_blank"> <Image src={linkedin} alt="Card image" fluid /></a>
            </Col>
             <Col xs={12} md={8}>
           
            <p>Vikas Nagpal joined Paragon Cables as a second generation entrepreneur, in 1991. He established Elektron to give a fresh direction to the legacy. Over the last three decades, he has gone from strength to strength and diversified the group into Energy, Lighting, EPC and Smart Technology verticals. As an engineer, he feels passionately about design and solution oriented innovation. He likes to keep updated on technological trends around the world and apply them to Elektron’s growth. He has been responsible for setting up advanced, state-of-the-art production lines that have enabled multi fold increase in production and testing capacities. His technical skills and vision are the driving force behind Elektron becoming the first in the world to develop advanced, ISI marked LED products. Prior to joining the family business, he lived and worked in Punjab at the largest electrical manufacturing unit at the time in India. This humbling and enlightening experience taught him a great deal about factory lines, productivity optimization and worker cultures. He contributes his ability to manage and build effective teams and facilities to this early experience. A gold medalist, he holds a degree in Electrical Engineering from Delhi College of Engineering (now known as DTU). Though he doesn’t hold a formal qualification, he read Law at Delhi University. He is deeply interested in the subject and is expert in corporate, commercial and intellectual law. He spends his free time reading, working out and travelling. </p>
            </Col>
        </Row>
       
         
            <Row className="mt-5">
            <Col xs={12} md={3} className="p-4" >
                    <Image src={team1} alt="Card image" className="mb-4 mt-4" fluid/>
                    <p>SS Shukla<br/>
                    <span>Vice President - Marketing</span></p>
            </Col>
            <Col xs={12} md={3} className="p-4">
                    <Image src={team2} alt="Card image" className="mb-4 mt-4" fluid/>
                    <p>PR Bhansode<br/>
                    <span>Head of Projects</span></p>
            </Col>
            <Col xs={12} md={3} className="p-4">
                    <Image src={team3} alt="Card image" className="mb-4 mt-4" fluid/>
                    <p>Mukul Jain<br/>
                    <span>Head of R&D</span></p>
            </Col>
            </Row>
           
            <Row>
            <Col xs={12} md={3} className="p-4" >
                    <Image src={team4} alt="Card image" className="mb-4 mt-4" fluid/>
                    <p>Prakash Pandey<br/>
                    <span>Head of Production</span></p>
            </Col>
            <Col xs={12} md={3} className="p-4">
                    <Image src={team5} alt="Card image" className="mb-4 mt-4" fluid/>
                    <p>HC Mishra<br/>
                    <span>Head of Quality Control</span></p>
            </Col>
            <Col xs={12} md={3} className="p-4">
                    <Image src={team6} alt="Card image" className="mb-4 mt-4" fluid/>
                    <p>Parveen Bansal<br/>
                    <span>Head of Finance and Accounting</span></p>
            </Col>
            </Row>

            <Row>
            <Col xs={12} md={3} className="p-4" >
                    <Image src={team7} alt="Card image" className="mb-4 mt-4" fluid/>
                    <p>Arjun Singh<br/>
                    <span>Human Resources and Administration</span></p>
            </Col>
            <Col xs={12} md={3} className="p-4">
                    <Image src={team8} alt="Card image" className="mb-4 mt-4" fluid/>
                    <p>Tanvi Nagpal<br/>
                    <span>Business Development and CSR Initiatives</span></p>
            </Col>
            <Col xs={12} md={3} className="p-4">
                    <Image src={team9} alt="Card image" className="mb-4 mt-4" fluid/>
                    <p>CP Verma<br/>
                    <span>Sr. Marketing Manager</span></p>
            </Col>
            </Row>

            <Row>
            <Col xs={12} md={3} className="p-4" >
                    <Image src={team10} alt="Card image" className="mb-4 mt-4" fluid/>
                    <p>Dinesh Singh<br/>
                    <span>Head Software Development</span></p>
            </Col>
            <Col xs={12} md={3} className="p-4">
                    <Image src={team11} alt="Card image" className="mb-4 mt-4" fluid/>
                    <p>Nikhil Bhadauriya<br/>
                    <span>Software Architect</span></p>
            </Col>
            <Col xs={12} md={3} className="p-4">
                    <Image src={team12} alt="Card image" className="mb-4 mt-4" fluid/>
                    <p>Koundinya Dhulipalla<br/>
                    <span>Communications Manager</span></p>
            </Col>
            </Row>

            <Row  className="text-center mt-5 mb-5">
            <Col>
            <h5>Join our team  <Button className="button m-2"><Link to="career">CAREERS</Link></Button></h5>
            </Col>
            </Row>
           
     </div>
    )
}

export default Teampage;