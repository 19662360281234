import React from 'react';
import {Row, Col, Image} from 'react-bootstrap'
import energyhead from '../assets/img/energyhead.jpg'
import energy1 from '../assets/img/energy1.svg'
import energy2 from '../assets/img/energy2.svg'
import energy3 from '../assets/img/energy3.svg'
import energy4 from '../assets/img/energy4.svg'
import energy5 from '../assets/img/energy5.svg'
import energy6 from '../assets/img/energy6.svg'
import energy7 from '../assets/img/energy7.svg'
import energy8 from '../assets/img/energy8.svg'
import en1 from '../assets/img/en1.jpg'
import en2 from '../assets/img/en2.jpg'
import en3 from '../assets/img/en3.jpg'
import en4 from '../assets/img/en4.jpg'
import en5 from '../assets/img/en5.jpg'
import en6 from '../assets/img/en6.jpg'
import en7 from '../assets/img/en1.svg'
import en8 from '../assets/img/en2.svg'
import en9 from '../assets/img/en3.svg'

const Energyefficiencypage = () => {
    return(
        <div>
        <Row>
            <Col xs={12} md={12} className="mt-4 mb-4">
            <h2>Energy Efficiency & Sustainability</h2>
            </Col>
             <Col xs={12} md={12}>
            <h3 className="blue-themetext mt-4">Harnessing Technology for a Greener World</h3>
            <p className="mt-4">We are building a greener world using cutting edge technologies and smart solutions. We are empowering governments, industries and individuals to make better decisions for the environment, while improving experiences and lifestyles. Our innovative products have been deployed all around the country and have been field tested to show high performance, energy savings and management convenience.  </p>
            </Col>
            </Row>
            <Row className="text-center">
           <Col  xs={12} md={4}>
           <Image src={en7} alt="Card image" className="mt-4" fluid/>
           <p className="blue-themetext">Energy</p>
           </Col>
           <Col  xs={12} md={4}>
           <Image src={en8} alt="Card image" className="mt-4" fluid/>
           <p className="blue-themetext">Water</p>
           </Col>
           <Col  xs={12} md={4}>
           <Image src={en9} alt="Card image" className="mt-4" fluid/>
           <p className="blue-themetext">Time</p>
           </Col>
            <Col xs={12} md={12} className="mb-5 mt-5">
            <Image src={energyhead} alt="Card image" fluid />
            </Col>
           </Row>
        <h3 className="blue-themetext mt-5 mb-5">Endless Possibilities</h3>
        <Row className="text-center">
           <Col  xs={12} md={3}>
           <Image src={energy2} alt="Card image" className="mt-4" fluid/>
           <p className="blue-themetext">Sensors</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={energy1} alt="Card image" className="mt-4" fluid/>
           <p className="blue-themetext">Controllers</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={energy3} alt="Card image" className="mt-4" fluid/>
           <p className="blue-themetext">Artificial Intelligence</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={energy4} alt="Card image" className="mt-4" fluid/>
           <p className="blue-themetext">Machine Learning Powered Optimization</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={energy8} alt="Card image" className="mt-4" fluid/>
           <p className="blue-themetext">Smart Homes</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={energy7} alt="Card image" className="mt-4" fluid/>
           <p className="blue-themetext">Smart LED</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={energy6} alt="Card image" className="mt-4" fluid/>
           <p className="blue-themetext">City Energy Dashboard</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={energy5} alt="Card image" className="mt-4" fluid/>
           <p className="blue-themetext">Water Conservation</p>
           </Col>
         </Row>

         <Row className="mt-5 mb-5">
           <Col  xs={12} md={12}>
           <p>Our IoT, programmable sensors and controllers have been deployed throughout all our EPC projects and proven to reduce a significant percentage of energy consumption each month. We have used this field tested technology to produce customized products for industries and governments. Using Artificial Intelligence and Machine Learning, our team is able to optimize performance and energy efficiency. We have used this platform in Residential, Commercial and Public spaces across devices like Lighting, Air Conditioners, Geysers, Heaters to ensure optimization and energy savings. Our Home App and City Dashboard and Platform provide the tools needed for data driven decision making and convenience. We are now working on building water conservation solutions for private clients. </p>
           </Col>
         </Row>
          
         <Row className="text-center mt-5 mb-5">
           <Col  xs={12} md={4}>
           <Image src={en1} alt="Card image" className="mt-4" fluid/>
           </Col>
           <Col  xs={12} md={4}>
           <Image src={en2} alt="Card image" className="mt-4" fluid/>
           </Col>
           <Col  xs={12} md={4}>
           <Image src={en3} alt="Card image" className="mt-4" fluid/>
           </Col>
           <Col  xs={12} md={4}>
           <Image src={en4} alt="Card image" className="mt-4" fluid/>
           </Col>
           <Col  xs={12} md={4}>
           <Image src={en5} alt="Card image" className="mt-4" fluid/>
           
           </Col>
           <Col  xs={12} md={4}>
           <Image src={en6} alt="Card image" className="mt-4" fluid/>
    
           </Col>
         </Row>
           
     </div>
    )
}

export default Energyefficiencypage;