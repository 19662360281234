import React from 'react';
import Clientspage from '../components/Clientspage';
import Container from 'react-bootstrap/Container'

function Clients() {
  return (
    <Container>
      <Clientspage />
      </Container>
      
    
  );
}

export default Clients;