import React from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Card, Button, Container, Modal, Image} from 'react-bootstrap'
import "./Productslider.css";
import ps1 from '../assets/img/cable/Aerial Bunched Cables.png'
import ps2 from '../assets/img/cable/Coaxial cables.png'
import ps3 from '../assets/img/cable/Computer Wires.png'
import ps4 from '../assets/img/cable/Control Cables.png'
import ps5 from '../assets/img/cable/Flexible Wires.png'
import ps6 from '../assets/img/cable/Instrumentaiton Cable.png'
import ps7 from '../assets/img/cable/LT Cables.png'
import ps8 from '../assets/img/cable/Mining Cable.png'
import ps9 from '../assets/img/cable/Special Cable.png'
import ps10 from '../assets/img/cable/Telephone Cable.png'

function PrM1(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={6}>
             <Image src={ps1} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={6}>
            <h4 className="mt-4 mb-4 ">Aerial Bunched Cables</h4>
			<p> IS:14255-1995 <br/>
			-Aluminium Alloy<br/>
			-HDPE/XLPE Insulation </p>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM2(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={6}>
             <Image src={ps2} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={6}>
            <h4 className="mt-4 mb-4 ">Coaxial Cables</h4>
			<p> -RG Series</p>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM3(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={6}>
             <Image src={ps3} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={6}>
            <h4 className="mt-4 mb-4 ">Computer Wires</h4>
			<p> -CAT-5, LAN Cable </p>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM4(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={6}>
             <Image src={ps4} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={6}>
            <h4 className="mt-4 mb-4 ">Control Cables</h4>
			<h5>IS:1554(Part-1)</h5>
			<p>-PVC/XLPE Insulated<br/>
			-HR/FR/FRLS type<br/>
			-upto 61 Cores in1.5/2.5 Sq.mm<br/>
			-multi core upto 50 Sqmm x 4 core<br/>
			-Copper Conductor<br/>
			-Armoured/Unarmoured </p>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM5(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={6}>
             <Image src={ps5} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={6}>
            <h4 className="mt-4 mb-4 ">Flexible Wires</h4>
			<h5> IS:694/1990</h5>
			<p> -Copper/Aluminium/Solid/Multistrand / Flexible<br/>
			 -PVC/FR/FRLS/HR/PVC insulated<br/>
			 -upto 185 sq.mm single core stranded /Hookup wire<br/>
			 -upto 10 sq.mm Solid<br/>
			 -upto 50 sq.mm multi-core round<br/>
			 -0.50 sqmm to 25 sq.m Flexible</p>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM6(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={6}>
             <Image src={ps6} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={6}>
            <h4 className="mt-4 mb-4 ">Instrumentation Cables</h4>
			<p> -Screened / Unscreened </p>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM7(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={6}>
             <Image src={ps7} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={6}>
            <h4 className="mt-4 mb-4 ">L.T. Cables upto 1.1KV Power</h4>
			<h5>IS:1554(Part-1)</h5>
			<h5>IS: 7098 (Part-1)</h5>
			<p>-PVC/XLPE Insulated<br/>
			-HR/FR/FRLS type<br/>
			-single core upto 1000 sq.mm<br/>
			-multi core upto 630 sq.mm<br/>
			-Aluminium /Copper Conductor<br/>
			-Armoured/Unarmoured </p>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM8(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={6}>
             <Image src={ps8} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={6}>
            <h4 className="mt-4 mb-4 ">Mining Cables Signaling Cables</h4>
			<h5> IS:1554(Part-1)</h5>

			<p> - Copper Conductor<br/>
			 - Steel Strip/Round Wire/ Double Armouring<br/>
			 - PVC/XLPE insulated</p>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM9(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={6}>
             <Image src={ps9} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={6}>
            <h4 className="mt-4 mb-4 ">Special Cables</h4>
			<p>-As per Customer’s Specification</p>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM10(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={6}>
             <Image src={ps10} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={6}>
            <h4 className="mt-4 mb-4 ">Telephone Cables</h4>
			<p> -In Pairs, Single Pair to 100 Pairs<br/>
as per ITD Specifications 
</p>
		<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }


 
  function Productslider () {
	const [modalShow1, setModalShow1] = React.useState(false);
	const [modalShow2, setModalShow2] = React.useState(false);
	const [modalShow3, setModalShow3] = React.useState(false);
	const [modalShow4, setModalShow4] = React.useState(false);
	const [modalShow5, setModalShow5] = React.useState(false);
	const [modalShow6, setModalShow6] = React.useState(false);
	const [modalShow7, setModalShow7] = React.useState(false);
	const [modalShow8, setModalShow8] = React.useState(false);
	const [modalShow9, setModalShow9] = React.useState(false);
	const [modalShow10, setModalShow10] = React.useState(false);
	// const [modalShow2, setModal] = React.useState(false);
  
   return (
	   <div className="blue-themebg mt-5 mb-4">
		   <Container className="pt-5 pb-5">
	<h3 className="text-white">Product Range</h3>
    <div className="product-slider mt-5 mb-4">
	<div id="productslide"  className="blue-themebg product-slide-track">

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps1} />
		<Card.Body>
			<Card.Text>Aerial Bunched Cables</Card.Text>
			<Button className="button" onClick={() => setModalShow1(true)}>MORE DETAILS</Button>
			 <PrM1 show={modalShow1} onHide={() => setModalShow1(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps2} />
		<Card.Body>
			<Card.Text>Coaxial Cables</Card.Text>
			<Button className="button" onClick={() => setModalShow2(true)}>MORE DETAILS</Button>
			 <PrM2 show={modalShow2} onHide={() => setModalShow2(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps3} />
		<Card.Body>
			<Card.Text>Computer Wires</Card.Text>
			<Button className="button" onClick={() => setModalShow3(true)}>MORE DETAILS</Button>
			 <PrM3 show={modalShow3} onHide={() => setModalShow3(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps4} />
		<Card.Body>
			<Card.Text>Control Cables</Card.Text>
			<Button className="button" onClick={() => setModalShow4(true)}>MORE DETAILS</Button>
			 <PrM4 show={modalShow4} onHide={() => setModalShow4(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps5} />
		<Card.Body>
			<Card.Text>Flexible Wires</Card.Text>
			<Button className="button" onClick={() => setModalShow5(true)}>MORE DETAILS</Button>
			 <PrM5 show={modalShow5} onHide={() => setModalShow5(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps6} />
		<Card.Body>
			<Card.Text>Instrumentation Cables</Card.Text>
			<Button className="button" onClick={() => setModalShow6(true)}>MORE DETAILS</Button>
			 <PrM6 show={modalShow6} onHide={() => setModalShow6(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps7} />
		<Card.Body>
			<Card.Text>L.T. Cables upto 1.1KV Power</Card.Text>
			<Button className="button" onClick={() => setModalShow7(true)}>MORE DETAILS</Button>
			 <PrM7 show={modalShow7} onHide={() => setModalShow7(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps8} />
		<Card.Body>
			<Card.Text>Mining Cables Signaling Cables</Card.Text>
			<Button className="button" onClick={() => setModalShow8(true)}>MORE DETAILS</Button>
			 <PrM8 show={modalShow8} onHide={() => setModalShow8(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps9} />
		<Card.Body>
			<Card.Text>Special Cables</Card.Text>
			<Button className="button" onClick={() => setModalShow9(true)}>MORE DETAILS</Button>
			 <PrM9 show={modalShow9} onHide={() => setModalShow9(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps10} />
		<Card.Body>
			<Card.Text>Telephone Cables</Card.Text>
			<Button className="button" onClick={() => setModalShow10(true)}>MORE DETAILS</Button>
			 <PrM10 show={modalShow10} onHide={() => setModalShow10(false)}/>
        </Card.Body>
		</Card>
		</div>

    	{/* <div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={pslide2} />
		<Card.Body>
			<Card.Text>
			Co-axial Cables
			</Card.Text>
			<Button className="button" onClick={() => setModal(true)}>MORE DETAILS</Button>
			<MyModal show={modalShow2} onHide={() => setModal(false)}/>
           
		</Card.Body>
		</Card>
		</div> */}


	</div>
</div>
</Container>
</div>
        )
    }

 

// ReactDOM.render(<DemoCarousel />, document.querySelector('.demo-carousel'));
export default Productslider;

