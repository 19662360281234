import React from 'react';
import Container from 'react-bootstrap/Container'
import Csrpage from '../components/Csrpage';

function Csr() {
  return (
      <div>
    <Container>
      <Csrpage />
      </Container>
    </div>
  );
}

export default Csr;