import React from 'react';
import {Row, Col, Image} from 'react-bootstrap'
import lighting from '../assets/img/lighting.jpg'
import isi from '../assets/img/isi.png'
import lighting2 from '../assets/img/lighting2.svg'
import lighting3 from '../assets/img/lighting3.svg'
import lighting1 from '../assets/img/lighting1.svg'
import mii from '../assets/img/makeinindia.png'

const Lightingpage = () => {
    return(
        <div>
        <Row>
            <Col xs={12} md={12} className="mt-4 mb-4">
            <h2>Lighting</h2>
            </Col>
            <Col xs={12} md={12} className="mb-5">
            <Image src={lighting} alt="Card image" fluid />
            </Col>
            <Col xs={12} md={12} className="mb-4 text-center">
            <Image src={isi} alt="Card image"  width="20%" fluid />
            </Col>
             <Col xs={12} md={12}>
            <p className="mt-4">Elektron Lighting Systems was launched in the year 2012 for manufacturing of LED Lights with a specialized focus on the Street Lights & Outdoor Lighting segment, in alignment with India’s mission to become energy efficient. Specialised customised LED products were developed for hot & humid weather of India, to ensure optimal performance irrespective of external conditions. Their cutting edge R&D supported our inhouse team to deliver truly intelligent and energy efficient products.</p>
            <p>To ascertain and establish the quality objectives, Elektron LED Modules were submitted for 9 months long Lumen Depreciation testing as part of the first ever application for use of the prestigious ISI Mark on any LED Product in the World. In 2014, Elektron  passed all the stringent tests and was awarded as the World’s First ISI Marked LED Product, conforming to IS:16103 Part (1&2). Till date Elektron remains the only LED product to have attempted and passed the tough parameters, no other company or manufacturer has ever established the performance capabilities.</p>
            </Col>
            
        </Row>
        <h3 className="blue-themetext mt-5 mb-5">Elektron LED Advantage</h3>
        <Row className="text-center">
           
           <Col  xs={12} md={4}>
           <Image src={lighting1} alt="Card image" className="mt-4" fluid/>
           <p className="blue-themetext">Ultralong Life</p>
           </Col>
           <Col  xs={12} md={4}>
           <Image src={lighting2} alt="Card image" className="mt-4" fluid/>
           <p className="blue-themetext">Uniform Illumination<br/><span>(Batwing Optical Distribution)</span></p>
           </Col>
           <Col  xs={12} md={4}>
           <Image src={lighting3} alt="Card image" className="mt-4" fluid/>
           <p className="blue-themetext">Guaranteed Energy Savings </p>
           </Col>
         </Row>
         <h3 className="blue-themetext mt-5 mb-5">Global Innovation Localized</h3>
         <Row className="mt-5 mb-5">
           <Col  xs={12} md={7}>
           <p>The LED Street Lights & Outdoor lighting systems are indigenously designed & manufactured in India in our own factories in Delhi, Haryana and Aurangabad. The LED chips & electronic hardware is imported from all over the world to make available the absolute best quality performance to Indian customers with an extended lifespan of above 50K hours with minimal in field maintenance. Our team continuously upgrades its designs in line with the advances in the electronics fields, working closely with Global leaders like ST Microelectronics & Texas Instruments. The LED Lights are manufactured from the Axial & SMD components stage upwards. All products are tested for compliance to EMI/EMC under relevant International Standards. Elektron is registered with BIS under CRS for manufacture of LED Drivers & LED Street Lights.  </p>
           </Col>
           <Col  xs={12} md={5}>
           <Image src={mii} alt="Card image" className="mt-4" fluid/>
           </Col>
         </Row>
          
        
           
     </div>
    )
}

export default  Lightingpage;