import React from 'react';
import {Row, Col} from 'react-bootstrap'

const Internships = () => {
    return(
        <Row>
             <Col xs={12} md={12}>
            <h2>Internships</h2>
            </Col>
            <Col xs={12} md={10}>
              <p >Please send us an email with your resume, along with a cover letter to info@elektron.com for internship enquiries.</p>
            </Col>
        </Row>
    )
}

export default Internships;