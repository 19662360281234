import React from 'react';
import Powerfirstsection from '../components/Powerfirstsection';
import Container from 'react-bootstrap/Container'
import Productslider from '../components/Productslider';

function Power() {
  return (
      <div>
    <Container>
      <Powerfirstsection />
      </Container>
      <Productslider />
       
    </div>
  );
}

export default Power;