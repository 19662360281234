import React from 'react';
import {Row, Col, Card, Image} from 'react-bootstrap'
import mdlogo from '../assets/img/mdlogo.png'

const Mdmessagepage = () => {
    return(
        <div>
        <Row>
             <Col xs={12} md={10} className="mt-4 mb-5">
            <h1>MD's Message</h1>
            <Card className="blue-themebg text-white p-5 mt-5" >
            <p>I thank all Customers, Vendors & Employees for supporting us in our tremendous journey spanning the past six decades. </p>
 
            <p> Elektron is proud to have pursued the path of continuous growth of it’s skills & capabilities. Learning from our experiences each day, step by step, we have witnessed our organisation expand from a Cables and Wire Manufacturer to a group spanning across Lighting, EPC Contracting, Software and IOT verticals, geared and ready for the exciting future.</p>
                
            <p> The convergence of technologies today is yielding possibilities not imaginable a decade before. To be able to deliver such solutions, diverse skills must be continued to be groomed and nurtured in house. Elektron is armed with hands on expertise & proven reliability and is aggressively positioned to deliver challenging products & solutions. </p>
                
            <p> The successful execution of City Wide Projects on BOT basis has boosted our confidence in managing the essential services in Public Domain. Some of the products typical for the needs of the City were custom designed and programmed to facilitate ease of operation & maintenance.</p>
                
            <p> Some of our newer product lines are targeted to the FMEG segment, we are providing hardware, firmware and software solutions with mobile app control & management of customised Smart Devices for residential, commercial  & industrial sectors. </p>
                
            <p> We look forward to influencing our society to make our country proud of us.</p>
                
            <p> To achieve our goals, I seek continued patronage of our valued customers, cooperation of our employees and thank all our well-wishers who have contributed to the growth of our organisation.
                
                </p>
            <Row>
            <Col xs={8} md={10} className="mt-4 ">
            <h5>Vikas Nagpal</h5>
            <p> Managing Director</p>
            </Col>
            <Col xs={4} md={2} className="text-right">
            <Image src={mdlogo} alt="Card image" className="mb-4 mt-4" fluid width="90%"/>
            </Col>
            </Row>
           </Card>
            </Col>
        </Row>
       
     </div>
    )
}

export default Mdmessagepage;