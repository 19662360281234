import React from 'react';
import Energyefficiencypage from '../components/Energyefficiencypage';
import Container from 'react-bootstrap/Container'

function Energyefficiency() {
  return (
      <div>
    <Container>
      <Energyefficiencypage />
      </Container>
       
    </div>
  );
}

export default Energyefficiency;