import React from 'react';
import './Footer.css';
import {Row, Col, Image} from 'react-bootstrap'
import {Link} from 'react-router-dom';
import linkedin from '../assets/img/linkedin.svg'

import wa from '../assets/img/wa.svg'

import logo from '../assets/img/elektronlogo.png';


const Footer = () => {
    return(

        <div>
        <div id="foot" className="d-none d-md-block">
        <Row className="bg-theme text-white footer-pd">
           <Col xs={12} md={4} id="footaddress" className="mx-auto">
           <Link to="/"  className="p-0" ><img width="50%" src={logo} alt="..." /></Link>
           <h6   className="mb-5 mt-2" >(Parent group of Paragon Cables)</h6>
           <p>© Elektron Group 2020</p>
           <p><span>Elektron House, 9, R. Udyog Nagar <br/>New Delhi - 110033, India</span></p>
           <p><a href="http://www.linkedin.com/company/elektron-group" target="_blank"> <Image src={linkedin} alt="Card image" fluid /></a>
           <a href="https://wa.me/918750032000?text=Hello!" target="_blank"> <Image src={wa} alt="Card image" fluid /></a></p>
           
           </Col>
            <Col xs={6} md={2}>
             <ul className="navstyle">
            <p> <Link to="/">Home</Link></p>
             <p className="mb-0">About</p>
             <li> <Link to="Company-profile">Company's Profile</Link></li>
             <li><Link to="MD-message">MD's Message</Link></li>
             <li> <Link to="Key-People">Key People</Link></li>
             <li><Link to="Our-journey">Our Journey</Link></li>
             <li> <Link to="Quality-assurance">Quality Assurance</Link></li>
             <li> <Link to="CSR">CSR</Link></li>
            </ul>
            </Col>
            <Col xs={6} md={2}>
            <ul className="navstyle">
            <p className="mb-0">Industries</p>
             <li><Link to="power">Power</Link></li>
             <li> <Link to="Energy">Energy</Link></li>
             <li><Link to="Technology">Technology</Link></li>
             <p className="mt-4 mb-0">Portfolio</p>
             <li><Link to="clients">Clients</Link></li>
            </ul>
            </Col>
            <Col xs={6} md={2}>
            <ul className="navstyle">
            <p className="mb-0">Solutions</p>
             <li><Link to="Cables-and-Wires">Wires & Cables</Link></li>
             <li> <Link to="Lighting">Lighting</Link></li>
             <li><Link to="EPC">EPC</Link></li>
             <li> <Link to="Smart-City-Management">Smart City Management</Link></li>
             <li><Link to="IOT">IoT</Link></li>
             <li> <Link to="Energy-Efficiency">Energy Efficiency</Link></li>
            </ul>
            </Col>
            <Col xs={6} md={2}>
            <ul className="navstyle">
            <p> <Link to="360-Experience">360Experience</Link></p>
            <p className="mb-0">Contact</p>
             <li> <Link to="career">Careers & Internships</Link></li>
             <li><Link to="contact">Get in Touch</Link></li>
            </ul>
            </Col>
            <Col xs={6} md={12} id="footb" className="text-center mt-5">
            <Link to="/technical-guidelines">Technical Guidelines</Link>
            <Link to="/Approvals">Approvals</Link>
            <Link to="/Downloads">Downloads</Link>
            <Link to="PrivacyPolicy">Privacy Policy</Link>
            <Link to="Terms-Conditions">T&C</Link>
            </Col>
            </Row>
            </div>

             <div id="foot" className="d-block d-sm-none text-center">
             <Row className="bg-theme text-white">
                <Col xs={12} md={4} className="mx-auto  mt-5">
                <Link to="/"><img  width="50%" src={logo} alt="..." /></Link>
                <h6   className="mb-3 mt-2" >(Parent group of Paragon Cables)</h6>
                </Col>
                 <Col xs={12}>
                  <ul className="navstyle pr-5 mt-5 mb-5">
                 <li> <Link to="technical-guidelines">Technical Guidelines</Link></li>
                 <li> <Link to="/Approvals">Approvals</Link></li>
                 <li> <Link to="/Downloads">Downloads</Link></li>
                 <li> <Link to="PrivacyPolicy">Privacy Policy</Link></li>
                 <li> <Link to="Terms-Conditions">T&C</Link></li>
                  </ul>
                 </Col>
                 <Col xs={12}>
                 <p>© Elektron Group 2020</p>
                 <p><span>Elektron House, 9, R. Udyog Nagar <br/>New Delhi - 110033, India</span></p>
                 <p><a href="http://www.linkedin.com/company/elektron-group" target="_blank"> <Image src={linkedin} alt="Card image" fluid /></a>
                 <a href="https://wa.me/918750032000?text=Hello!" target="_blank"> <Image src={wa} alt="Card image" fluid /></a></p>
                 </Col>
                
                 </Row>
                 </div>
                 </div>
    )
      }

      export default Footer;