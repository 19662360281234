import React from 'react';

import {Row,Col, Image} from 'react-bootstrap';
import homet1 from '../assets/img/homet1.svg'
import homet2 from '../assets/img/homet2.svg'
import homet3 from '../assets/img/homet3.svg'
import homet4 from '../assets/img/homet4.svg'

const Homegridsingle = () => {
   
    return(
        <Row className="text-center p-4 mt-5 mb-5">
             <Col xs={12} md={3}>
                <div className="text-center p-4">
                <Image src={homet4} alt="Card image" className="mb-4 mt-4" fluid/>
                <h5 className="blue-themetext">Power </h5>
                <h6>A legacy of six decades in engineering and manufacturing of a broad range of power cables and wires. Over 150 + Certificates and Approvals.</h6>
                </div>
            </Col>
            <Col xs={12} md={3}>
                <div className="text-center p-4">
                <Image src={homet1} alt="Card image" className="mb-4 mt-4" fluid/>
                <h5 className="blue-themetext">Energy</h5>
                <h6>Strong experience in designing, building and operating optimized energy efficiency systems to create sustainable solutions across fields.  </h6>
                </div>
            </Col>
            <Col xs={12} md={3}>
                <div className="text-center p-4">
                <Image src={homet2} alt="Card image" className="mb-4 mt-4" fluid/>
                <h5 className="blue-themetext">Lighting</h5>
                <h6>Innovative and intelligent LED solutions manufactured and engineered to perfection. Strong expertise in Street Lights and Smart City Lighting Optimization solutions. </h6>
                </div>
            </Col>
            <Col xs={12} md={3}>
                <div className="text-center p-4">
                <Image src={homet3} alt="Card image" className="mb-4 mt-4" fluid/>
                <h5 className="blue-themetext">Smart Tech</h5>
                <h6>Established team with robust technical, mechanical and electrical capabilities to provide end-to-end product design, manufacturing and testing expertise. </h6>
                </div>
            </Col>
            
        </Row>
    )
}

export default Homegridsingle;