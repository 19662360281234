import React from 'react';
import Energypage from '../components/Energypage';
import Lightingslider from '../components/Lightingslider';
import Energypageprefooter from '../components/Energypageprefooter';
import Container from 'react-bootstrap/Container'

function Energy() {
  return (
      <div>
    <Container>
      <Energypage />
      </Container>
      <Lightingslider />
      <Container>
      <Energypageprefooter />
      </Container>
    </div>
  );
}

export default Energy;