import React from 'react';
import Clientspage from '../components/Clientspage';
import Container from 'react-bootstrap/Container'
import Guidelinespage from '../components/Guidelinespage';

function Guidelines() {
  return (
    <Container>
      <Guidelinespage />
      </Container>
      
    
  );
}

export default Guidelines;