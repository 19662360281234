import React from 'react';
import {Row, Col, Image} from 'react-bootstrap'
import power1 from '../assets/img/power1.jpg'
import power2 from '../assets/img/power2.jpg'
import power3 from '../assets/img/power3.jpg'
import imgparagon from '../assets/img/4.jpg'
import img1 from '../assets/img/5.jpg'

const Powerfirstsection = () => {
    return(
        <div>
        <Row>
            <Col xs={12} md={12} className="mt-4 mb-4">
            <h2>Power</h2>
            <h3 className="blue-themetext mt-4">A Legacy of Connections</h3>
            </Col>
             <Col xs={12} md={8}>
             <Image src={imgparagon} alt="Card image" className="mb-4" fluid  width="15%"/>
            <p>Paragon is a well established brand of Wires & Cables enjoying pan India presence. We have been manufacturing Electrical Power Cables since 1962 and are proud to be associated as vendors to almost all the Power Distribution Utilities like BSES, NDPL, Powergrid, State owned Utilities like MSEDCL, PSPCL, Generation Utilities as NTPC, BHEL, MAHAGENCO  and many others. In the Private segment our products are regularly used by TATA Projects, Bajaj Electricals, L&T, Siemens, Voltas, Transrail and many renowned companies involved in Power Electrification & Distribution Projects. Our Cables are approved & preferred by PWDs, CPWD, NBCC, MES, Railways, ONGC, GAIL - amongst Government Organisations.</p>
            </Col>
            <Col xs={12} md={4}>
            <Image src={img1} alt="Card image" fluid />
            </Col>
        </Row>
         <Row className="mt-5">
        
          <Col xs={12} md={4} className="mb-4">
          <Image src={power1} alt="Card image" fluid />
         </Col>
         <Col xs={12} md={4} className="mb-4">
         <Image src={power2} alt="Card image" fluid />
         </Col>
         <Col xs={12} md={4} className="mb-4">
         <Image src={power3} alt="Card image" fluid />
         </Col>
         
     </Row>
     </div>
    )
}

export default Powerfirstsection;