import React from 'react';
import Lightingpage from '../components/Lightingpage';
import Lightingslider from '../components/Lightingslider';
import Container from 'react-bootstrap/Container'

function Lighting() {
  return (
      <div>
    <Container>
      <Lightingpage />
      </Container>
       < Lightingslider />
    </div>
  );
}

export default Lighting;