import React from 'react';
import Cablefirst from '../components/Cablefirst';
import Container from 'react-bootstrap/Container'
import Productslider from '../components/Productslider';
import Cablelast from '../components/Cablelast';

function Cable() {
  return (
      <div>
    <Container>
      <Cablefirst />
      </Container>
      <Productslider />
      <Container>
      <Cablelast />
      </Container>
       
    </div>
  );
}

export default Cable;