import React from 'react';
import {Row, Col} from 'react-bootstrap'

const Hometext = () => {
    return(
        <Row>
             <Col xs={12} md={5}>
            <h2 className="blue-themetext">Shaping a secure, connected and sustainable future</h2>
            </Col>
            <Col xs={12} md={7}>
              <p >Driven by a passion for technology and a strong belief in the power of engineering solutions, Elektron Group has grown from a manufacturing expert to a complete solution provider. We have robust expertise in engineering, manufacturing and testing, and are rapidly growing our IT proficiency. We are at an exciting point in the company’s journey, enabling us to to engage with diverse industries and sectors. 
 </p>
            </Col>
        </Row>
    )
}

export default Hometext;