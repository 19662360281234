import React from 'react';
import Ourjourneypage from '../components/Ourjourneypage';
import Container from 'react-bootstrap/Container'

function Ourjourney() {
  return (
      <div>
    <Container>
      <Ourjourneypage />
      </Container>
       
    </div>
  );
}

export default Ourjourney;