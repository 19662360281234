import React from 'react';
import {Row, Col, Image, Button} from 'react-bootstrap'
import './Ourjourneypage.css'

const Ourjourneypage = () => {
    return(
        <div>
        <Row>
            <Col xs={12} md={12} className="mt-4 mb-4">
                <h1>Our Journey</h1>
            </Col>
            
        </Row>
       
         
            <Row className="mt-5">
            <Col xs={12} md={{span:8,offset:2}} className="p-4" >
            <section class="timeline">
                    <div class="container">

                    <div class="timeline-item">
                    <div class="timeline-img"></div>
                    <div class="timeline-content">
                        <div class="date">1962</div>
                        <h5>Paragon Group launched by Nagpal Brothers</h5>
                    </div>
                    </div>

                    <div class="timeline-item">
                    <div class="timeline-img"></div>
                        <div class="timeline-content">
                        <div class="date">1980</div>
                        <h5>Established a Pan India Presence</h5>
                    </div>

                    </div>

                    <div class="timeline-item">
                    <div class="timeline-img"></div>
                        <div class="timeline-content js--fadeInLeft">
                        <div class="date">1982</div>
                        <h5>Paragon Group becomes the lead material partner for Asiad Games Village</h5>
                        
                    </div>
                    </div>

                    <div class="timeline-item">
                    <div class="timeline-img"></div>
                        <div class="timeline-content">
                        <div class="date">1991</div>
                        <h5>Paragon Cables India (PCI) Division was established with a focus on manufacturing Power Cables </h5>
                    </div>
                    </div>

                    <div class="timeline-item">
                     <div class="timeline-img"></div>
                        <div class="timeline-content timeline-card">
                        <div class="date">2001</div>
                        <h5>An international grade product line introduced under the brand of Elektron Wires & Cables</h5>
                       
                    </div>
                    </div>

                    <div class="timeline-item">
                    <div class="timeline-img"></div>
                        <div class="timeline-content timeline-card">
                        <div class="date">2010</div>
                        <h5>Paragon Group becomes lead material partner for Commonwealth Games</h5>
                        </div>
                    </div>

                    <div class="timeline-item">
                    <div class="timeline-img"></div>
                         <div class="timeline-content">
                        <div class="date">2011</div>
                        <h5>Established approvals from BHEL, PowerGrid, Indian Railways, MES, PWD, CPWD, NBCC, BSES, Reliance Infra, Tata Power, Voltas, Siemens, Bajaj, Phillips, L&T, and many other public & private institutions </h5>
                    </div>
                    </div>

                    <div class="timeline-item">
                    <div class="timeline-img"></div>
                        <div class="timeline-content timeline-card">
                       <div class="date">2012</div>
                       <h5>Brands integrated into Elektron Group <br/>
                        Lighting & Energy Efficiency verticals established</h5>
                       </div>
                    </div>

                    <div class="timeline-item">
                    <div class="timeline-img"></div>
                        <div class="timeline-content">
                        <div class="date">2014</div>
                        <h5>Elektron becomes the first in the World to have an ISI marked LED Product</h5>
                        </div>
                    </div>

                    <div class="timeline-item">
                    <div class="timeline-img"></div>
                        <div class="timeline-content">
                        <div class="date">2016</div>
                        <h5>EPC vertical established for end-to-end execution of large scale public and private projects</h5>
                        </div>
                    </div>

                    <div class="timeline-item">
                    <div class="timeline-img"></div>
                        <div class="timeline-content">
                        <div class="date">2017</div>
                        <h5>Built a first-of-its-kind, tech-driven City Management platform geared towards Smart Cities</h5>
                        </div>
                    </div>

                    <div class="timeline-item">
                    <div class="timeline-img"></div>
                        <div class="timeline-content">
                        <div class="date">2018</div>
                        <h5> IoT, Firmware & Software capabilities added</h5>
                        </div>
                    </div>

                    <div class="timeline-item">
                    <div class="timeline-img"></div>
                        <div class="timeline-content">
                        <div class="date">2019</div>
                        <h5>Elektron brand relaunch to reflect upgraded FMEG capabilities</h5>
                        </div>
                    </div>
                        
                    </div>
                    </section>
            </Col>
           
            </Row>
           
     </div>
    )
}

export default Ourjourneypage;