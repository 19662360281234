import React from 'react';
import Container from 'react-bootstrap/Container'
import Downloadspage from '../components/Downloadspage';

function Downloads() {
  return (
    <Container>
      <Downloadspage />
      </Container>
      
    
  );
}

export default Downloads;