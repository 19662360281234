import React from 'react';
import {Row, Col, Image} from 'react-bootstrap'
import logo1 from '../assets/img/client/lg01.png'
import logo2 from '../assets/img/client/lg02.png'
import logo3 from '../assets/img/client/lg03.png'
import logo4 from '../assets/img/client/lg04.png'
import logo5 from '../assets/img/client/lg05.png'
import logo6 from '../assets/img/client/lg06.png'
import logo7 from '../assets/img/client/lg07.png'
import logo8 from '../assets/img/client/lg08.png'
import logo9 from '../assets/img/client/lg09.png'
import logo10 from '../assets/img/client/lg10.png'
import logo11 from '../assets/img/client/lg11.png'
import logo12 from '../assets/img/client/lg12.png'
import logo13 from '../assets/img/client/lg13.png'
import logo14 from '../assets/img/client/lg14.png'
import logo15 from '../assets/img/client/lg15.png'
import logo16 from '../assets/img/client/lg16.png'
import logo17 from '../assets/img/client/lg17.png'
import logo18 from '../assets/img/client/lg18.png'
import logo19 from '../assets/img/client/lg19.png'
import logo20 from '../assets/img/client/lg20.png'
import logo21 from '../assets/img/client/lg21.png'
import logo22 from '../assets/img/client/lg22.png'
import logo23 from '../assets/img/client/lg23.png'
import logo24 from '../assets/img/client/lg24.png'
import logo25 from '../assets/img/client/lg25.png'
import logo26 from '../assets/img/client/lg26.png'
import logo27 from '../assets/img/client/lg27.png'
import logo28 from '../assets/img/client/lg28.png'
import logo29 from '../assets/img/client/lg29.png'
import logo30 from '../assets/img/client/lg30.png'
import logo31 from '../assets/img/client/lg31.png'
import logo32 from '../assets/img/client/lg32.png'
import logo33 from '../assets/img/client/lg33.png'
import logo34 from '../assets/img/client/lg34.png'
import logo35 from '../assets/img/client/lg35.png'
import logo36 from '../assets/img/client/lg36.png'
import logo37 from '../assets/img/client/lg37.png'
import logo38 from '../assets/img/client/lg38.png'
import logo39 from '../assets/img/client/lg39.png'
import logo40 from '../assets/img/client/lg40.png'
import logo41 from '../assets/img/client/lg41.png'
import logo42 from '../assets/img/client/lg42.png'
import logo43 from '../assets/img/client/lg43.png'
import logo44 from '../assets/img/client/lg44.png'
import logo45 from '../assets/img/client/lg45.png'
import logo46 from '../assets/img/client/lg46.png'
import logo47 from '../assets/img/client/lg47.png'
import logo48 from '../assets/img/client/lg48.png'
import logo49 from '../assets/img/client/lg49.png'
import logo50 from '../assets/img/client/lg50.png'
import logo51 from '../assets/img/client/lg51.png'
import logo52 from '../assets/img/client/lg52.png'
import logo53 from '../assets/img/client/lg53.png'
import logo54 from '../assets/img/client/lg54.png'
import logo55 from '../assets/img/client/lg55.png'
import logo56 from '../assets/img/client/lg56.png'
import logo57 from '../assets/img/client/lg57.png'
import logo58 from '../assets/img/client/lg58.png'
import logo59 from '../assets/img/client/lg59.png'
import logo60 from '../assets/img/client/lg60.png'
import logo61 from '../assets/img/client/lg61.png'
import logo62 from '../assets/img/client/lg62.png'
import logo63 from '../assets/img/client/lg63.png'
import logo64 from '../assets/img/client/lg64.png'
import logo65 from '../assets/img/client/lg65.png'
import logo66 from '../assets/img/client/lg66.png'
import logo67 from '../assets/img/client/lg67.png'
import logo68 from '../assets/img/client/lg68.png'
import logo69 from '../assets/img/client/lg69.png'
import logo70 from '../assets/img/client/lg70.png'
import logo71 from '../assets/img/client/lg71.png'
import logo72 from '../assets/img/client/lg72.png'
import logo73 from '../assets/img/client/lg73.png'
import logo74 from '../assets/img/client/lg74.png'
import logo75 from '../assets/img/client/lg75.png'
import logo76 from '../assets/img/client/lg76.png'
import logo77 from '../assets/img/client/lg77.png'
import logo78 from '../assets/img/client/lg78.png'
import logo79 from '../assets/img/client/lg79.png'
import logo80 from '../assets/img/client/lg80.png'

const Clientspage = () => {
    return(
        <Row>
             <Col xs={12} md={12} className="mt-4 mb-4">
            <h1>Featured Clients</h1>
            </Col>
             <Col xs={6} md={3} className="p-4">
             <a href="https://www.bajajelectricals.com/" target="_blank"><Image src={logo1} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.tataprojects.com/" target="_blank"><Image src={logo2} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.jindalsteelpower.com/" target="_blank"><Image src={logo3} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.larsentoubro.com/" target="_blank"><Image src={logo4} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://new.siemens.com/in/en.html" target="_blank"><Image src={logo5} alt="Card image" fluid /></a>
            </Col>
            
            <Col xs={6} md={3} className="p-4">
             <a href="http://smb.gov.in/" target="_blank"><Image src={logo6} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://www.indianrailways.gov.in/" target="_blank"><Image src={logo7} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.bluestarindia.com/" target="_blank"><Image src={logo8} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href=""><Image src={logo9} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://mcchandigarh.gov.in/" target="_blank"><Image src={logo10} alt="Card image" fluid /></a>
            </Col>

            <Col xs={6} md={3} className="p-4">
             <a href="http://www.pdymun.in/" target="_blank"><Image src={logo11} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.bsnl.co.in/" target="_blank"><Image src={logo12} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.bsesdelhi.com/web/bses" target="_blank"><Image src={logo13} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.bhel.com/bhel-landing/" target="_blank"><Image src={logo14} alt="Card image" fluid /></a>
            </Col>

            <Col xs={6} md={3} className="p-4">
             <a href="https://www.rinfra.com/" target="_blank"><Image src={logo15} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://www.sudhirpower.com/" target="_blank"><Image src={logo16} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.tatapower-ddl.com/" target="_blank"><Image src={logo17} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href=""><Image src={logo18} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href=""><Image src={logo19} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href=""><Image src={logo20} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href=""><Image src={logo21} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href=""><Image src={logo22} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href=""><Image src={logo23} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href=""><Image src={logo24} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href=""><Image src={logo25} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://cselectric.co.in/" target="_blank"><Image src={logo26} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href=""><Image src={logo27} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://indianairforce.nic.in/" target="_blank"><Image src={logo28} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.joinindiannavy.gov.in/" target="_blank"><Image src={logo29} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://joinindianarmy.nic.in/default.aspx" target="_blank"><Image src={logo30} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://cpwd.gov.in/" target="_blank"><Image src={logo31} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.crompton.co.in/" target="_blank"><Image src={logo32} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href=""><Image src={logo33} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href=""><Image src={logo34} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://dgmap.gov.in" target="_blank"><Image src={logo35} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://delhitourism.gov.in/delhitourism/dttdc/tourism.jsp" target="_blank"><Image src={logo36} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://www.dynamicpower.in/" target="_blank"><Image src={logo37} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://www.eciecc.com/" target="_blank"><Image src={logo38} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://www.eta-engg.com/about/eta-engineering.html" target="_blank"><Image src={logo39} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href=""><Image src={logo40} alt="Card image" fluid /></a>
            </Col>
            
            <Col xs={6} md={3} className="p-4">
             <a href="https://gmbports.org//" target="_blank"><Image src={logo41} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.uhbvn.org.in/web/portal/home" target="_blank"><Image src={logo42} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://ofb.gov.in/units/HVF" target="_blank"><Image src={logo43} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://ihbas.delhigovt.nic.in/wps/wcm/connect/DOIT_IHBAS1/ihbas/home" target="_blank"><Image src={logo44} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.ircon.org/index.php?lang=en" target="_blank"><Image src={logo45} alt="Card image" fluid /></a>
            </Col>
            
            <Col xs={6} md={3} className="p-4">
             <a href="http://www.ivrcl.com/" target="_blank"><Image src={logo46} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://www.jalindia.com/" target="_blank"><Image src={logo47} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.jkorg.in/index.php" target="_blank"><Image src={logo48} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://kfclkanpur.com/" target="_blank"><Image src={logo49} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://kbunl.co.in/" target="_blank"><Image src={logo50} alt="Card image" fluid /></a>
            </Col>
            
            <Col xs={6} md={3} className="p-4">
             <a href="http://www.atoz.co.in/" target="_blank"><Image src={logo51} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href=""><Image src={logo52} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://portal.mpcz.in/web/" target="_blank"><Image src={logo53} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.mahadiscom.in/" target="_blank"><Image src={logo54} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href=""><Image src={logo55} alt="Card image" fluid /></a>
            </Col>
            
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.tatapower-ddl.com/" target="_blank"><Image src={logo56} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://ofb.gov.in/" target="_blank"><Image src={logo57} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.philips.co.in/" target="_blank"><Image src={logo58} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://www.jkpdd.gov.in/" target="_blank"><Image src={logo59} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.powergridindia.com/" target="_blank"><Image src={logo60} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://prasarbharati.gov.in/" target="_blank"><Image src={logo61} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://www.punjlloydgroup.com/" target="_blank"><Image src={logo62} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.deltaelectronicsindia.com/energy-infrastructure/renewable-energy" target="_blank"><Image src={logo63} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.pspcl.in/" target="_blank"><Image src={logo64} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.pwddelhi.gov.in/" target="_blank"><Image src={logo65} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://rcf.indianrailways.gov.in/" target="_blank"><Image src={logo66} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://core.indianrailways.gov.in/" target="_blank"><Image src={logo67} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.rcom.co.in/our-company/reliance-group/" target="_blank"><Image src={logo68} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.rinfra.com/" target="_blank"><Image src={logo69} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href=""><Image src={logo70} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://posoco.in/" target="_blank"><Image src={logo71} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://www.simplexinfra.com/#ad-image-0" target="_blank"><Image src={logo72} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://www.spml.co.in/" target="_blank"><Image src={logo73} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://sterlingandwilson.com/" target="_blank"><Image src={logo74} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://www.supremeinfra.com/home.html" target="_blank"><Image src={logo75} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.svec.education/home/" target="_blank"><Image src={logo76} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.tvnl.in/" target="_blank"><Image src={logo77} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="http://www.vijaielectricals.com/" target="_blank"><Image src={logo78} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href=""><Image src={logo79} alt="Card image" fluid /></a>
            </Col>
            <Col xs={6} md={3} className="p-4">
             <a href="https://www.tatapower.com/" target="_blank"><Image src={logo80} alt="Card image" fluid /></a>
            </Col>

        </Row>
    )
}

export default Clientspage;