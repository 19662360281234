import React from 'react';
import {Row, Col, Image, Button} from 'react-bootstrap'

import {Link} from 'react-router-dom';
import cable1 from '../assets/img/cable1.svg'
import cable2 from '../assets/img/cable2.svg'
import cable3 from '../assets/img/cable3.svg'
import cable4 from '../assets/img/cable4.svg'

const Cablelast = () => {
    return(
      <div>
        <Row>
            <Col  xs={12} md={12} className="text-center ">
            <Button className="button m-2"><Link to="downloads">FOR MORE PRODUCTS DOWNLOAD OUR CATALOGUE</Link></Button>
            </Col>
            </Row>
           <Row className="mt-4 mb-5">
         <Col  xs={12} md={3} className="text-center" >
           <Image src={cable1} alt="Card image" className="mt-4" fluid/>
           </Col>
           <Col  xs={12} md={8}>
           <h3  className="blue-themetext mt-4 mb-4">50+ Years Experience</h3>
           <p>Paragon has been the flagship brand established in 1962 with the vision of manufacturing high quality wires and cables to connect and power India’s growth. We enjoy an impeccable reputation and a pan india presence and recognition across public and private sectors. Elektron brand cables were introduced for export quality and discerning customers.   </p>
           </Col>
         </Row>
         <Row className="mt-4 mb-5">
         <Col  xs={12} md={3} className="text-center">
           <Image src={cable2} alt="Card image" className="mt-4" fluid/>
           </Col>
           <Col  xs={12} md={8}>
           <h3  className="blue-themetext mt-4 mb-4">Safety Guarantee</h3>
           <p>We have a rigorous infrastructure of labs and testing facilities in house. We take great pride in our team of engineers and technicians that ensure high quality, tested and validated products. Our cables and wires are high quality and safe, compliant with national and global standards.</p>
           </Col>
         </Row>
         <Row className="mt-4 mb-5">
         <Col  xs={12} md={3} className="text-center">
           <Image src={cable3} alt="Card image" className="mt-4" fluid/>
           </Col>
           <Col  xs={12} md={8}>
           <h3  className="blue-themetext mt-4 mb-4"> Customizable</h3>
           <p>Our manufacturing capabilities allow a wide range of customization options for our customers. Our engineers work with our clients to create customized solutions. Please see our product range for reference. Please get in touch with our team to know more about our manufacturing capacities. </p>
           </Col>
         </Row>
         <Row className="mt-4 mb-5">
         <Col  xs={12} md={3} className="text-center">
           <Image src={cable4} alt="Card image" className="mt-4" fluid/>
           </Col>
           <Col  xs={12} md={8}>
           <h3  className="blue-themetext mt-4 mb-4">On Time Delivery</h3>
           <p>Six decades of experience that helped us operate our units at a highly efficient capacity ensuring timely production, testing and delivery of goods. You can count on us to guarantee an on-time delivery of orders. </p>
           </Col>
         </Row>
        </div>
       
    )
}

export default Cablelast;