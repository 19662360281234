import React from 'react';
import {Row, Col, Image, Button, Container} from 'react-bootstrap'
import service1 from '../assets/img/service1.png'
import service2 from '../assets/img/service2.png'
import service3 from '../assets/img/service3.png'
import service4 from '../assets/img/service4.png'
import service5 from '../assets/img/service5.png'
import service6 from '../assets/img/service6.png'
import service7 from '../assets/img/service7.png'
import service8 from '../assets/img/service8.png'

import us1 from '../assets/img/us1.svg'
import us2 from '../assets/img/us2.svg'
import us3 from '../assets/img/us3.svg'

const Experienceprefooter = () => {
    return(
        <div>
        <div className="blue-themebg text-white mt-5 mb-5">
        <Container>
        <h3 className="pt-5 mb-5">Our Services</h3>
            <Row className="m-0">
            <Col xs={12} md={12} >
                <Row>
                    <Col xs={12} md={4} >
                    <Image src={service1} alt="Card image" className="mb-4 p-4" fluid/>
                    </Col>
                    <Col xs={12} md={4} className="mt-4">
                    <h5>Embedded Software Engineering</h5>
                    <p>Firmware, Middleware<br/>
                        Device Driver<br/>
                        OS Porting and Customization<br/>
                        BSP<br/>
                        Embedded Application</p>
                    <h5 className="mt-5 mb-3">FPGA Design</h5>
                    <p>FPGA Design<br/>
                        RTL Coding and Verification<br/>
                        FPGA POC<br/>
                        Emulators & FPGA Prototyping</p>
                    <h5 className="mt-5 mb-3">Testing and QA</h5>   
                    <p>Agile<br/>
                    Testing and Validation<br/>
                    Interoperability Testing<br/>
                    QA Automation<br/>
                    Certification</p> 
                    </Col>
                    <Col xs={12} md={4} >
                    <h5>Hardware Engineering</h5>
                    <p>PCB Design and Layout<br/>
                        Schematic<br/>
                        Complex PCB Design<br/>
                        Low Power Design<br/>
                        RF, Wireless Engineering<br/>
                        Pre Compliance Testing and Certification</p>
                    <h5 className="mt-5 mb-3">Mechanical Engineering Design</h5>
                    <p>Product Design<br/>
                        3D Modelling <br/>
                        Industrial and Ruggedized Design<br/>
                        Thermal Analysis<br/>
                        Certifications</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4} >
                    <Image src={service2} alt="Card image" className="mt-4 mb-4 p-4" fluid/>
                    </Col>
                    <Col xs={12} md={4} className="align-self-center">
                    <p>PCB Assembly</p><p>
                        Product Assembly</p><p>
                        3D printers </p><p>
                        Testing Facilities</p><p>
                        Surface Mount Technology (SMT)</p><p>
                        Wave Soldering </p> 
                    </Col>
                    <Col xs={12} md={4} className="align-self-center">
                    <p>Injection Moulding</p><p>
                        Wiring Harness</p><p>
                        Wires & Cables</p><p>
                        Panels</p><p>
                        Sheet Metal Fabrication</p><p>
                        Extrusion</p><p>
                        Tool Rooms</p> 
                    </Col>
                    
                </Row>
                <Row>
                    <Col xs={12} md={4} >
                    <Image src={service6} alt="Card image" className="mt-4 mb-4 p-4" fluid/>
                    </Col>
                    <Col xs={12} md={4} className="align-self-center">
                        <h5>Wireless Expertise</h5>
                    <p>Wi-Fi<br/>
                        NBIoT, LTE<br/>
                        ZigBee<br/>
                        BLE 4.0,4.2,5.0<br/>
                        NFC, Bluetooth<br/>
                        2G, 3G- GPS/GSM/<br/>
                        GPRS/WCDMA</p>
                    </Col>
                    <Col xs={12} md={4} className="align-self-center">
                        <h5>Cloud  Expertise</h5>
                    <p>Cloud Native Development<br/>
                        Cloud Operation, Migration & Analytics<br/>
                        Cloud Infrastructure & Implementation<br/>
                        Cloud Application Development<br/>
                        Cloud Security<br/>
                        Edge Computing</p>
                    </Col>
                    
                </Row>
                <Row>
                    <Col xs={12} md={4} >
                    <Image src={service4} alt="Card image" className="mt-4 mb-4 p-4" fluid/>
                    </Col>
                    <Col xs={12} md={4} className="align-self-center">
                    <p>Application Development</p>
                   <p>UI/UX Design Services </p>
                    <p>Platform Independence </p>
                    </Col>
                    <Col xs={12} md={4} className="align-self-center">
                    <p>Multi Screen Devices </p>
                   <p>Audio/ Video Integration</p>
                    <p>System Apps</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4} >
                    <Image src={service7} alt="Card image" className="mt-4 mb-4 p-4" fluid/>
                    </Col>
                    <Col xs={12} md={4} className="align-self-center">
                    <p>Agile</p>
                    <p> Testing and Validation</p>
                    <p> Interoperability Testing</p>
                    <p> QA Automation</p>
                    <p> Certification</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4} >
                    <Image src={service8} alt="Card image" className="mt-4 mb-4 p-4" fluid/>
                    </Col>
                    <Col xs={12} md={4} className="align-self-center">
                    <p>User Behavior Expertise </p>
                    <p>  Industrial Design Solutions</p>
                    <p>   UX/ UI Services</p>
                    <p>  Design Thinking Driven </p>
                    <p>  Consumer Product Design Expertise </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4} >
                    <Image src={service5} alt="Card image" className="mt-4 mb-4 p-4" fluid/>
                    </Col>
                    <Col xs={12} md={8} className="align-self-center">
                    <p>Established Global Supply Chain</p> 
                    <p>Strong National & International Partnerships</p> 
                    <p>Electrical Supply Chain Expertise  </p>
                  
                    </Col>
                </Row>
            </Col>
            </Row>

           
           </Container>
     </div>
     <div className="mt-5 mb-5">
        <Container>
        <h3 className="blue-themetext pt-5 mb-5">Why Us</h3>
            <Row className="m-0 pb-5">
            <Col xs={12} md={{span:10, offset:1}} >
                <Row className="text-center">
                    <Col xs={12} md={4} >
                    <Image src={us3} alt="Card image" className="mt-4 p-4" fluid/>
                    <p className="blue-themetext">Transparency</p> 
                    </Col>
                    <Col xs={12} md={4} >
                    <Image src={us2} alt="Card image" className="mt-4 p-4" fluid/>
                    <p className="blue-themetext">Quality Guarantree</p> 
                    </Col>
                    <Col xs={12} md={4} >
                    <Image src={us1} alt="Card image" className="mt-4 p-4" fluid/>
                    <p className="blue-themetext">Innovation</p> 
                    </Col>
                </Row>
                
            </Col>
            </Row>

           
           </Container>
     </div>

     </div>
    )
}

export default Experienceprefooter;