import React from 'react';
import "./Clientslider.css";
import Image from 'react-bootstrap/Image'
import logo01 from '../assets/img/clientslide/01.png'
import logo02 from '../assets/img/clientslide/02.png'
import logo03 from '../assets/img/clientslide/03.png'
import logo04 from '../assets/img/clientslide/04.png'
import logo05 from '../assets/img/clientslide/05.png'
import logo06 from '../assets/img/clientslide/06.png'
import logo07 from '../assets/img/clientslide/07.png'
import logo08 from '../assets/img/clientslide/08.png'
import logo09 from '../assets/img/clientslide/09.png'
import logo10 from '../assets/img/clientslide/10.png'
import logo11 from '../assets/img/clientslide/11.png'
import logo12 from '../assets/img/clientslide/12.png'
import logo13 from '../assets/img/clientslide/13.png'
import logo14 from '../assets/img/clientslide/14.png'
import logo15 from '../assets/img/clientslide/15.png'
import logo16 from '../assets/img/clientslide/16.png'
import logo17 from '../assets/img/clientslide/17.png'
 
const Clientslider = () => {
   return (
	   <div>
	<h2 className="blue-themetext">CLIENTS</h2>
    <div className="logo-slider mt-5 mb-5">
	
	<div className="logo-slide-track">
		<div className="slide">
		<a href="https://www.bajajelectricals.com/" target="_blank"><Image src={logo01} alt="Card image" /></a>
		</div>
    	<div className="slide">
		<a href="https://www.tataprojects.com/" target="_blank"><Image src={logo02} alt="Card image" /></a>
		</div>
		<div className="slide">
		<a href="https://www.jindalsteelpower.com/" target="_blank"><Image src={logo03} alt="Card image" /></a>
		</div>
		<div className="slide">
		<a href="https://www.larsentoubro.com/" target="_blank"><Image src={logo04} alt="Card image" /></a>
		</div>
		<div className="slide">
		<a href="https://new.siemens.com/in/en.html" target="_blank"><Image src={logo05} alt="Card image" /></a>
		</div>
		<div className="slide">
		<a href="http://smb.gov.in/" target="_blank"><Image src={logo06} alt="Card image" /></a>
		</div>
		<div className="slide">
		<a href="http://www.indianrailways.gov.in/" target="_blank"><Image src={logo07} alt="Card image" /></a>
		</div>
		<div className="slide">
		<a href="https://www.bluestarindia.com/" target="_blank"><Image src={logo08} alt="Card image" /></a>
		</div>
		<div className="slide">
		<Image src={logo09} alt="Card image" />
		</div>
		<div className="slide">
		<a href="http://mcchandigarh.gov.in/" target="_blank"><Image src={logo10} alt="Card image" /></a>
		</div>
		<div className="slide">
		<a href="http://www.pdymun.in/" target="_blank"><Image src={logo11} alt="Card image" /></a>
		</div>
		<div className="slide">
		<a href="https://www.bsnl.co.in/" target="_blank"><Image src={logo12} alt="Card image" /></a>
		</div>
		<div className="slide">
		<a href="https://www.bsesdelhi.com/web/bses" target="_blank"><Image src={logo13} alt="Card image" /></a>
		</div>
		<div className="slide">
		<a href="https://www.bhel.com/bhel-landing/" target="_blank"><Image src={logo14} alt="Card image" /></a>
		</div>
		<div className="slide">
		<a href="https://www.rinfra.com/" target="_blank"><Image src={logo15} alt="Card image" /></a>
		</div>
		<div className="slide">
		<a href="http://www.sudhirpower.com/" target="_blank"><Image src={logo16} alt="Card image" /></a>
		</div>
		<div className="slide">
		<a href="https://www.tatapower-ddl.com/" target="_blank"><Image src={logo17} alt="Card image" /></a>
		</div>
		
	</div>
</div>
</div>
        )
    }

 

// ReactDOM.render(<DemoCarousel />, document.querySelector('.demo-carousel'));
export default Clientslider;

