import React, {Component} from 'react';
import {Row, Col, Card, Tab, Nav, Button} from 'react-bootstrap';
import Tabletop from 'tabletop';
import './Currentopening.css'

class Currentopening extends Component {
    constructor() {
      super()
      console.log('step1')
      this.state = {
        data: []
      }
    }
  
    componentDidMount() {
        console.log('step2')
      Tabletop.init({
        key: '1K87sjyGGrTX06pWAZ1wb0i_9u_nGcdDIoC-AtS62z-o',
        callback: googleData => {
          console.log('google sheet data --->', googleData)
          this.setState({data:googleData})
        },
        simpleSheet: true
      })
    }
  
    render() {
        console.log('step3',this.state.data)
        let newdata = this.state.data
        // let activeID = (newdata.length>0 && newdata[0].Jobtitle!=undefined ) ? newdata[0].Jobtitle : '';
        return (
        
        <Tab.Container id="left-tabs-example" defaultActiveKey="Front-end Engineer">
        
        <Row className="mt-4 mb-4">
             <Col sm={12} md={12}>
            <h2 className="mb-5">Current Openings</h2>
            </Col>
            <Col sm={12} md={4}>
            <Card id="currentopeningnav" className="p-5">
            <Nav variant="pills" className="flex-column">
            <Nav.Item>
            {
           (newdata.length>0)?
                newdata.map(obj => {
                  return (
                      
               
                    <Nav.Link eventKey={obj.Jobtitle}>{obj.Jobtitle}</Nav.Link>
               
                  )
                }): <p className="text-white">No vacancies</p>
              }
            </Nav.Item>
            </Nav>
            </Card>
            </Col>
            <Col sm={12} md={8} className="mt-4 mb-4">
            <Tab.Content id="currentopening">
            {
           (newdata.length>0)?
                newdata.map(obj => {
                  return (
                      
                <Tab.Pane eventKey={obj.Jobtitle}>
                     <h3>{obj.Jobtitle}</h3>
                    <p>{obj.Description}</p>
                    <h4>{obj.Heading1}</h4>
                    <p>{obj.Description11}</p>
                    <p>{obj.Description12}</p>
                    <p>{obj.Description13}</p>
                    <p>{obj.Description14}</p>

                    <h4>{obj.Heading2}</h4>
                    <p>{obj.Description21}</p>
                    <p>{obj.Description22}</p>
                    <p>{obj.Description23}</p>
                    <p>{obj.Description24}</p>
                  
                </Tab.Pane>
                 )
                }):'-'
              }
           </Tab.Content>
           <h3>To apply, please email your resume/CV, along with a cover letter to info@elektron.com</h3>
            </Col>
        </Row>
          
        </Tab.Container>
        );
      }
    
  }

export default Currentopening;