import React from 'react';
import {Link} from 'react-router-dom';
import {Carousel, Button} from 'react-bootstrap';
import './Homeslider.css'
import Slide1 from '../assets/img/home1.jpg';
import Slide2 from '../assets/img/home2.jpg';
import Slide3 from '../assets/img/home3.jpg';
import Slide4 from '../assets/img/home4.jpg';
import Slide5 from '../assets/img/home5.jpg';
import Slide6 from '../assets/img/home6.jpg';

function Homeslider() {
  

  return (
    <div>
    <Carousel id="slide" className="mt-4 mb-5" controls={false}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Slide1}
          alt="First slide"
        />
        <Carousel.Caption className="">
          <h3>Making Reliable Connections</h3>
          <h5><Button className="button"><Link to="Cables-and-Wires">Cables & Wires</Link></Button></h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Slide2}
          alt="Second slide"
        />
         <Carousel.Caption className="">
          <h3>Smart Lighting</h3>
          <h5><Button className="button"><Link to="Lighting">LED Lighting Solutions</Link></Button></h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Slide3}
          alt="Third slide"
        />
         <Carousel.Caption className="">
          <h3>Build Operate Transfer</h3>
          <h5><Button className="button"><Link to="EPC">EPC Projects</Link></Button></h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Slide4}
          alt="Third slide"
        />
         <Carousel.Caption className="">
          <h3>Turbocharged Cities</h3>
          <h5><Button className="button"><Link to="Smart-City-Management">Smart City Management</Link></Button></h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Slide5}
          alt="Third slide"
        />
         <Carousel.Caption className="">
          <h3>Solve Problems</h3>
          <h5><Button className="button"><Link to="IOT">IoT and ODM lines</Link></Button></h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Slide6}
          alt="Third slide"
        />
         <Carousel.Caption className="">
          <h3>Sustainability</h3>
          <h5><Button className="button"><Link to="Energy-Efficiency">Energy Efficient Solutions</Link></Button></h5>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    {/* <iframe src="http://www.youtube.com/embed/xzvScRnF6MU?autoplay=1&amp;controls=0" width="960" height="447" frameborder="0" allowfullscreen></iframe> */}
    </div>
  )
};

export default Homeslider;