import React from 'react';
import Container from 'react-bootstrap/Container'
import Mdmessagepage from '../components/Mdmessagepage';

function Mdmessage() {
  return (
      <div>
    <Container>
      <Mdmessagepage />
      </Container>
    </div>
  );
}

export default Mdmessage;