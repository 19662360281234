import React from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Card,Table, Button, Container, Modal, Image} from 'react-bootstrap'
import "./Lightingslider.css";
import ps1 from '../assets/img/lighting/ELS13M67.png'
import ps2 from '../assets/img/lighting/ELS22M67.png'
import ps3 from '../assets/img/lighting/ELS30M67.png'
import ps4 from '../assets/img/lighting/ELS38M67.png'
import ps5 from '../assets/img/lighting/ELS43M67.png'
import ps6 from '../assets/img/lighting/ELS20PG01.png'
import ps7 from '../assets/img/lighting/ELS25NG01.png'
import ps8 from '../assets/img/lighting/ELS30PG01.png'
import ps9 from '../assets/img/lighting/ELS45NG02.png'
import ps10 from '../assets/img/lighting/ELS50FL00.png'
import ps11 from '../assets/img/lighting/ELS50PG01.png'
import ps12 from '../assets/img/lighting/ELS75PG01.png'
import ps13 from '../assets/img/lighting/ELS80FL01.png'
import ps14 from '../assets/img/lighting/ELS100NG03.png'
import ps15 from '../assets/img/lighting/ELS100PG01.png'
import ps16 from '../assets/img/lighting/ELS150NG04.png'
import ps17 from '../assets/img/lighting/ELS160FL02.png'
import ps18 from '../assets/img/lighting/ELS200FL03.png'
import ps19 from '../assets/img/lighting/ELS200NG05.png'

function PrM1(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps1} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
            <h4 className="mt-4 mb-4 ">ISI Marked LED Module - ELS13M67 </h4>
			<p>Conforming to IS:16103 Part (1 & 2) :2012</p>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Input Driving Voltage DC</td>
				<td>36V</td></tr>
			<tr><td>Input Driving Current DC</td>
				<td>350mA</td></tr>
			<tr><td>Maximum Rated Nominal Power</td>
				<td>13 W</td></tr>
			<tr><td>Number of LEDs</td>
				<td>12 LED - CREE</td></tr>
			<tr><td>Lumens</td>
				<td>1500 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Rated Life </td>
				<td>50000 Hrs</td></tr>
			<tr><td>IP Rating </td>
				<td>IP67</td></tr>
			<tr><td>Lumen maintenance code</td>
				<td>L70</td></tr>
			<tr><td>Ambient Temperature Range	</td>
				<td>-10°C to 50°C</td></tr>
			<tr><td>Efficacy (lm/Watt)  </td>
				<td>115 lm/Watt</td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM2(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps2} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
			<h4 className="mt-4 mb-4 ">ISI Marked LED Module - ELS22M67 </h4>
			<p>Conforming to IS:16103 Part (1 & 2) :2012</p>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Input Driving Voltage DC</td>
				<td>36V</td></tr>
			<tr><td>Input Driving Current DC</td>
				<td>610mA</td></tr>
			<tr><td>Maximum Rated Nominal Power</td>
				<td>22 W</td></tr>
			<tr><td>Number of LEDs</td>
				<td>12 LED - CREE</td></tr>
			<tr><td>Lumens</td>
				<td>2100 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Rated Life </td>
				<td>50000 Hrs</td></tr>
			<tr><td>IP Rating </td>
				<td>IP67</td></tr>
			<tr><td>Lumen maintenance code</td>
				<td>L70</td></tr>
			<tr><td>Ambient Temperature Range	</td>
				<td>-10°C to 50°C</td></tr>
			<tr><td>Efficacy (lm/Watt)  </td>
				<td>95 lm/Watt</td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM3(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps3} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
            <h4 className="mt-4 mb-4 ">ISI Marked LED Module - ELS30M67 </h4>
			<p>Conforming to IS:16103 Part (1 & 2) :2012</p>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Input Driving Voltage DC</td>
				<td>48 V</td></tr>
			<tr><td>Input Driving Current DC</td>
				<td>610mA</td></tr>
			<tr><td>Maximum Rated Nominal Power</td>
				<td>30 W</td></tr>
			<tr><td>Number of LEDs</td>
				<td>16 LED - CREE</td></tr>
			<tr><td>Lumens</td>
				<td>2400 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Rated Life </td>
				<td>50000 Hrs</td></tr>
			<tr><td>IP Rating </td>
				<td>IP67</td></tr>
			<tr><td>Lumen maintenance code</td>
				<td>L70</td></tr>
			<tr><td>Ambient Temperature Range	</td>
				<td>-10°C to 50°C</td></tr>
			<tr><td>Efficacy (lm/Watt)  </td>
				<td>95 lm/Watt</td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM4(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps4} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
            <h4 className="mt-4 mb-4 ">ISI Marked LED Module - ELS38M67 </h4>
			<p>Conforming to IS:16103 Part (1 & 2) :2012</p>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Input Driving Voltage DC</td>
				<td>54V</td></tr>
			<tr><td>Input Driving Current DC</td>
				<td>700mA</td></tr>
			<tr><td>Maximum Rated Nominal Power</td>
				<td>38 W</td></tr>
			<tr><td>Number of LEDs</td>
				<td>18 LED - CREE</td></tr>
			<tr><td>Lumens</td>
				<td>3600 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Rated Life </td>
				<td>50000 Hrs</td></tr>
			<tr><td>IP Rating </td>
				<td>IP67</td></tr>
			<tr><td>Lumen maintenance code</td>
				<td>L70</td></tr>
			<tr><td>Ambient Temperature Range	</td>
				<td>-10°C to 50°C</td></tr>
			<tr><td>Efficacy (lm/Watt)  </td>
				<td>100 lm/Watt</td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM5(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps5} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
			<h4 className="mt-4 mb-4 ">ISI Marked LED Module - ELS43M67 </h4>
			<p>Conforming to IS:16103 Part (1 & 2) :2012</p>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Input Driving Voltage DC</td>
				<td>72V</td></tr>
			<tr><td>Input Driving Current DC</td>
				<td>600mA</td></tr>
			<tr><td>Maximum Rated Nominal Power</td>
				<td>43 W</td></tr>
			<tr><td>Number of LEDs</td>
				<td>24 LED - CREE</td></tr>
			<tr><td>Lumens</td>
				<td>4500 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Rated Life </td>
				<td>50000 Hrs</td></tr>
			<tr><td>IP Rating </td>
				<td>IP67</td></tr>
			<tr><td>Lumen maintenance code</td>
				<td>L70</td></tr>
			<tr><td>Ambient Temperature Range	</td>
				<td>-10°C to 50°C</td></tr>
			<tr><td>Efficacy (lm/Watt)  </td>
				<td>100 lm/Watt</td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM6(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps6} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
            <h4 className="mt-4 mb-4 ">LED Street Lights - ELS20PG01</h4>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Luminaire</td>
				<td>20 Watt</td></tr>
			<tr><td>Street Light Model Number</td>
				<td>ELS20PG01</td></tr>
			<tr><td>Input Voltage AC </td>
				<td>100-265V</td></tr>
			<tr><td>Total power Consumption</td>
				<td>20W (Including Driver)</td></tr>
			<tr><td>Lumens</td>
				<td>≥1600 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Power Factor  </td>
				<td>0.95</td></tr>
			<tr><td>IP Rating (Luminaire, LED Module) </td>
				<td>IP 65, IP67</td></tr>
			<tr><td>Enclosure</td>
				<td>Aluminium and PC </td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM7(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps7} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
            <h4 className="mt-4 mb-4 ">LED Street Lights - ELS25NG01</h4>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Luminaire</td>
				<td>25 Watt</td></tr>
			<tr><td>Street Light Model Number</td>
				<td>ELS25NG01</td></tr>
			<tr><td>Input Voltage AC </td>
				<td>100-265V</td></tr>
			<tr><td>Total power Consumption</td>
				<td>25W (Including Driver)</td></tr>
			<tr><td>Rated Output Lumen</td>
				<td>2100 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Power Factor  </td>
				<td>0.95</td></tr>
			<tr><td>IP Rating (Luminaire, LED Module) </td>
				<td>IP 65, IP67</td></tr>
			<tr><td>Enclosure</td>
				<td>Aluminium and PC </td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM8(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps8} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
            <h4 className="mt-4 mb-4 ">LED Street Lights - ELS30PG01</h4>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Luminaire</td>
				<td>30 Watt</td></tr>
			<tr><td>Street Light Model Number</td>
				<td>ELS30PG01</td></tr>
			<tr><td>Input Voltage AC </td>
				<td>100-265V</td></tr>
			<tr><td>Total power Consumption</td>
				<td>30W (Including Driver)</td></tr>
			<tr><td>Lumens</td>
				<td>≥2400 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Power Factor  </td>
				<td>0.95</td></tr>
			<tr><td>IP Rating (Luminaire, LED Module) </td>
				<td>IP 65, IP67</td></tr>
			<tr><td>Enclosure</td>
				<td>Aluminium and PC </td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM9(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps9} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
            <h4 className="mt-4 mb-4 ">LED Street Lights - ELS45NG02</h4>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Luminaire</td>
				<td>45 Watt</td></tr>
			<tr><td>Street Light Model Number</td>
				<td>ELS45NG02</td></tr>
			<tr><td>Input Voltage AC </td>
				<td>100-265V</td></tr>
			<tr><td>Total power Consumption</td>
				<td>45W (Including Driver)</td></tr>
			<tr><td>Lumens</td>
				<td>≥4500 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Power Factor  </td>
				<td>0.95</td></tr>
			<tr><td>IP Rating (Luminaire, LED Module) </td>
				<td>IP 65, IP67</td></tr>
			<tr><td>Enclosure</td>
				<td>Aluminium and PC </td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM10(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps10} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
            <h4 className="mt-4 mb-4 ">LED Flood Lights - ELS50FL00</h4>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Luminaire</td>
				<td>50 Watt</td></tr>
			<tr><td>Flood Light  Model Number</td>
				<td>ELS50FL00</td></tr>
			<tr><td>Input Voltage AC </td>
				<td>100-265V</td></tr>
			<tr><td>Total power Consumption</td>
				<td>50W (Including Driver)</td></tr>
			<tr><td>Lumens</td>
				<td>≥4250 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Power Factor  </td>
				<td>0.95</td></tr>
			<tr><td>IP Rating (Luminaire, LED Module) </td>
				<td>IP 65, IP67</td></tr>
			<tr><td>Enclosure</td>
				<td>Aluminium and PC </td></tr>
			</tbody>
			</Table>
		<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM11(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps11} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
			<h4 className="mt-4 mb-4 ">LED Street Lights - ELS50PG01</h4>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Luminaire</td>
				<td>50 Watt</td></tr>
			<tr><td>Street Light Model Number</td>
				<td>ELS50PG01</td></tr>
			<tr><td>Input Voltage AC </td>
				<td>100-265V</td></tr>
			<tr><td>Total power Consumption</td>
				<td>50W (Including Driver)</td></tr>
			<tr><td>Lumens</td>
				<td>≥4500 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Power Factor  </td>
				<td>0.95</td></tr>
			<tr><td>IP Rating (Luminaire, LED Module) </td>
				<td>IP 65, IP67</td></tr>
			<tr><td>Enclosure</td>
				<td>Aluminium and PC </td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM12(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps12} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
			<h4 className="mt-4 mb-4 ">LED Street Lights - ELS75PG01</h4>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Luminaire</td>
				<td>75 Watt</td></tr>
			<tr><td>Street Light  Model Number</td>
				<td>ELS75PG01</td></tr>
			<tr><td>Input Voltage AC </td>
				<td>100-265V</td></tr>
			<tr><td>Total power Consumption</td>
				<td>75W (Including Driver)</td></tr>
			<tr><td>Lumens</td>
				<td>≥7200 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Power Factor  </td>
				<td>0.95</td></tr>
			<tr><td>IP Rating (Luminaire, LED Module) </td>
				<td>IP 65, IP67</td></tr>
			<tr><td>Enclosure</td>
				<td>Aluminium and PC </td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM13(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps13} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
			<h4 className="mt-4 mb-4 ">LED Flood Lights - ELS80FL01</h4>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Luminaire</td>
				<td>80 Watt</td></tr>
			<tr><td>Flood Light  Model Number</td>
				<td>ELS80FL01</td></tr>
			<tr><td>Input Voltage AC </td>
				<td>100-265V</td></tr>
			<tr><td>Total power Consumption</td>
				<td>80W (Including Driver)</td></tr>
			<tr><td>Lumens</td>
				<td>≥6800 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Power Factor  </td>
				<td>0.95</td></tr>
			<tr><td>IP Rating (Luminaire, LED Module) </td>
				<td>IP 65, IP67</td></tr>
			<tr><td>Enclosure</td>
				<td>Aluminium and PC </td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM14(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps14} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
			<h4 className="mt-4 mb-4 ">LED Street Lights - ELS100NG03</h4>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Luminaire</td>
				<td>100 Watt</td></tr>
			<tr><td>Street Light  Model Number</td>
				<td>ELS100NG03</td></tr>
			<tr><td>Input Voltage AC </td>
				<td>100-265V</td></tr>
			<tr><td>Total power Consumption</td>
				<td>100W (Including Driver)</td></tr>
			<tr><td>Lumens</td>
				<td>9000 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Power Factor  </td>
				<td>0.95</td></tr>
			<tr><td>IP Rating (Luminaire, LED Module) </td>
				<td>IP 65, IP67</td></tr>
			<tr><td>Enclosure</td>
				<td>Aluminium and PC </td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM15(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps15} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
			<h4 className="mt-4 mb-4 ">LED Street Lights - ELS100PG01</h4>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Luminaire</td>
				<td>100 Watt</td></tr>
			<tr><td>Street Light  Model Number</td>
				<td>ELS100PG01</td></tr>
			<tr><td>Input Voltage AC </td>
				<td>100-265V</td></tr>
			<tr><td>Total power Consumption</td>
				<td>100W (Including Driver)</td></tr>
			<tr><td>Lumens</td>
				<td>9000 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Power Factor  </td>
				<td>0.95</td></tr>
			<tr><td>IP Rating (Luminaire, LED Module) </td>
				<td>IP 65, IP67</td></tr>
			<tr><td>Enclosure</td>
				<td>Aluminium and PC </td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM16(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps16} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
			<h4 className="mt-4 mb-4 ">LED Street Lights - ELS150NG04</h4>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Luminaire</td>
				<td>150 Watt</td></tr>
			<tr><td>Street Light  Model Number</td>
				<td>ELS150NG04</td></tr>
			<tr><td>Input Voltage AC </td>
				<td>100-265V</td></tr>
			<tr><td>Total power Consumption</td>
				<td>150W (Including Driver)</td></tr>
			<tr><td>Lumens</td>
				<td>13500 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Power Factor  </td>
				<td>0.95</td></tr>
			<tr><td>IP Rating (Luminaire, LED Module) </td>
				<td>IP 65, IP67</td></tr>
			<tr><td>Enclosure</td>
				<td>Aluminium and PC </td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM17(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps17} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
            <h4 className="mt-4 mb-4 ">LED Flood Lights - ELS160FL02</h4>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Luminaire</td>
				<td>160 Watt</td></tr>
			<tr><td>Flood Light Model Number</td>
				<td>ELS160FL02</td></tr>
			<tr><td>Input Voltage AC </td>
				<td>100-265V</td></tr>
			<tr><td>Total power Consumption</td>
				<td>160W (Including Driver)</td></tr>
			<tr><td>Rated Output Lumen</td>
				<td>≥13600 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Power Factor  </td>
				<td>0.95</td></tr>
			<tr><td>IP Rating (Luminaire, LED Module) </td>
				<td>IP 65, IP67</td></tr>
			<tr><td>Enclosure</td>
				<td>Aluminium and PC </td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM18(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps18} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
            <h4 className="mt-4 mb-4 ">LED Flood Lights - ELS200FL03</h4>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Luminaire</td>
				<td>200 Watt</td></tr>
			<tr><td>Flood Light Model Number</td>
				<td>ELS200FL03</td></tr>
			<tr><td>Input Voltage AC </td>
				<td>100-265V</td></tr>
			<tr><td>Total power Consumption</td>
				<td>200W (Including Driver)</td></tr>
			<tr><td>Lumens</td>
				<td>≥17000 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Power Factor  </td>
				<td>0.95</td></tr>
			<tr><td>IP Rating (Luminaire, LED Module) </td>
				<td>IP 65, IP67</td></tr>
			<tr><td>Enclosure</td>
				<td>Aluminium and PC </td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  function PrM19(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
		<Row>
             <Col xs={12} md={4}>
             <Image src={ps19} alt="Card image" fluid />
            </Col>
			<Col xs={12} md={8}>
            <h4 className="mt-4 mb-4 ">LED Street Lights - ELS200NG05</h4>
			<Table responsive size="sm">
			<thead><tr><th>Specification</th><th></th></tr></thead>
			<tbody>
			<tr><td>Luminaire</td>
				<td>200 Watt</td></tr>
			<tr><td>Street Light Model Number</td>
				<td>ELS200NG05</td></tr>
			<tr><td>Input Voltage AC </td>
				<td>100-265V</td></tr>
			<tr><td>Total power Consumption</td>
				<td>150W (Including Driver)</td></tr>
			<tr><td>Lumens</td>
				<td>18000 Lm</td></tr>
			<tr><td>Colour 	</td>
				<td>Cool White</td></tr>
			<tr><td>Power Factor  </td>
				<td>0.95</td></tr>
			<tr><td>IP Rating (Luminaire, LED Module) </td>
				<td>IP 65, IP67</td></tr>
			<tr><td>Enclosure</td>
				<td>Aluminium and PC </td></tr>
			</tbody>
			</Table>
			<Button className="button"><Link to="contact">REQUEST A QUOTE</Link></Button>
            </Col>
		</Row>
        </Modal.Header>
      </Modal>
    );
  }

  


 
  function Lightingslider () {
	const [modalShow1, setModalShow1] = React.useState(false);
	const [modalShow2, setModalShow2] = React.useState(false);
	const [modalShow3, setModalShow3] = React.useState(false);
	const [modalShow4, setModalShow4] = React.useState(false);
	const [modalShow5, setModalShow5] = React.useState(false);
	const [modalShow6, setModalShow6] = React.useState(false);
	const [modalShow7, setModalShow7] = React.useState(false);
	const [modalShow8, setModalShow8] = React.useState(false);
	const [modalShow9, setModalShow9] = React.useState(false);
	const [modalShow10, setModalShow10] = React.useState(false);
	const [modalShow11, setModalShow11] = React.useState(false);
	const [modalShow12, setModalShow12] = React.useState(false);
	const [modalShow13, setModalShow13] = React.useState(false);
	const [modalShow14, setModalShow14] = React.useState(false);
	const [modalShow15, setModalShow15] = React.useState(false);
	const [modalShow16, setModalShow16] = React.useState(false);
	const [modalShow17, setModalShow17] = React.useState(false);
	const [modalShow18, setModalShow18] = React.useState(false);
	const [modalShow19, setModalShow19] = React.useState(false);
  
   return (
	   <div className="blue-themebg mt-5 mb-4">
		   <Container className="pt-5 pb-5">
	<h3 className="text-white">Product Range</h3>
    <div className="lighting-slider mt-5 mb-4">
	<div id="productslide"  className="blue-themebg lighting-slide-track">

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps1} />
		<Card.Body>
			<Card.Text>ISI Marked LED Module - ELS13M67</Card.Text>
			<Button className="button" onClick={() => setModalShow1(true)}>MORE DETAILS</Button>
			 <PrM1 show={modalShow1} onHide={() => setModalShow1(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps2} />
		<Card.Body>
			<Card.Text>ISI Marked LED Module - ELS22M67</Card.Text>
			<Button className="button" onClick={() => setModalShow2(true)}>MORE DETAILS</Button>
			 <PrM2 show={modalShow2} onHide={() => setModalShow2(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps3} />
		<Card.Body>
			<Card.Text>ISI Marked LED Module - ELS30M67</Card.Text>
			<Button className="button" onClick={() => setModalShow3(true)}>MORE DETAILS</Button>
			 <PrM3 show={modalShow3} onHide={() => setModalShow3(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps4} />
		<Card.Body>
			<Card.Text>ISI Marked LED Module - ELS38M67</Card.Text>
			<Button className="button" onClick={() => setModalShow4(true)}>MORE DETAILS</Button>
			 <PrM4 show={modalShow4} onHide={() => setModalShow4(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps5} />
		<Card.Body>
			<Card.Text>ISI Marked LED Module - ELS43M67</Card.Text>
			<Button className="button" onClick={() => setModalShow5(true)}>MORE DETAILS</Button>
			 <PrM5 show={modalShow5} onHide={() => setModalShow5(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps6} />
		<Card.Body>
			<Card.Text>LED Street Lights - ELS20PG01</Card.Text>
			<Button className="button" onClick={() => setModalShow6(true)}>MORE DETAILS</Button>
			 <PrM6 show={modalShow6} onHide={() => setModalShow6(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps7} />
		<Card.Body>
			<Card.Text>LED Street Lights - ELS25NG01</Card.Text>
			<Button className="button" onClick={() => setModalShow7(true)}>MORE DETAILS</Button>
			 <PrM7 show={modalShow7} onHide={() => setModalShow7(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps8} />
		<Card.Body>
			<Card.Text>LED Street Lights - ELS30PG01</Card.Text>
			<Button className="button" onClick={() => setModalShow8(true)}>MORE DETAILS</Button>
			 <PrM8 show={modalShow8} onHide={() => setModalShow8(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps9} />
		<Card.Body>
			<Card.Text>LED Street Lights - ELS45NG02</Card.Text>
			<Button className="button" onClick={() => setModalShow9(true)}>MORE DETAILS</Button>
			 <PrM9 show={modalShow9} onHide={() => setModalShow9(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps10} />
		<Card.Body>
			<Card.Text>LED Flood Lights - ELS50FL00</Card.Text>
			<Button className="button" onClick={() => setModalShow10(true)}>MORE DETAILS</Button>
			 <PrM10 show={modalShow10} onHide={() => setModalShow10(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps11} />
		<Card.Body>
			<Card.Text>LED Street Lights - ELS50PG01</Card.Text>
			<Button className="button" onClick={() => setModalShow11(true)}>MORE DETAILS</Button>
			 <PrM11 show={modalShow11} onHide={() => setModalShow11(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps12} />
		<Card.Body>
			<Card.Text>LED Street Lights - ELS75PG01</Card.Text>
			<Button className="button" onClick={() => setModalShow12(true)}>MORE DETAILS</Button>
			 <PrM12 show={modalShow12} onHide={() => setModalShow12(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps13} />
		<Card.Body>
			<Card.Text>LED Flood Lights - ELS80FL01</Card.Text>
			<Button className="button" onClick={() => setModalShow13(true)}>MORE DETAILS</Button>
			 <PrM13 show={modalShow13} onHide={() => setModalShow13(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps14} />
		<Card.Body>
			<Card.Text>LED Street Lights - ELS100NG03</Card.Text>
			<Button className="button" onClick={() => setModalShow14(true)}>MORE DETAILS</Button>
			 <PrM14 show={modalShow14} onHide={() => setModalShow14(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps15} />
		<Card.Body>
			<Card.Text>LED Street Lights - ELS100PG01</Card.Text>
			<Button className="button" onClick={() => setModalShow15(true)}>MORE DETAILS</Button>
			 <PrM15 show={modalShow15} onHide={() => setModalShow15(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps16} />
		<Card.Body>
			<Card.Text>LED Street Lights - ELS150NG04</Card.Text>
			<Button className="button" onClick={() => setModalShow16(true)}>MORE DETAILS</Button>
			 <PrM16 show={modalShow16} onHide={() => setModalShow16(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps17} />
		<Card.Body>
			<Card.Text>LED Flood Lights - ELS160FL02</Card.Text>
			<Button className="button" onClick={() => setModalShow17(true)}>MORE DETAILS</Button>
			 <PrM17 show={modalShow17} onHide={() => setModalShow17(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps18} />
		<Card.Body>
			<Card.Text>LED Flood Lights - ELS200FL03</Card.Text>
			<Button className="button" onClick={() => setModalShow18(true)}>MORE DETAILS</Button>
			 <PrM18 show={modalShow18} onHide={() => setModalShow18(false)}/>
        </Card.Body>
		</Card>
		</div>

		<div className="slide">
		<Card style={{ width: '18rem' }}>
		<Card.Img variant="top" src={ps19} />
		<Card.Body>
			<Card.Text>LED Street Lights - ELS200NG05</Card.Text>
			<Button className="button" onClick={() => setModalShow19(true)}>MORE DETAILS</Button>
			 <PrM19 show={modalShow19} onHide={() => setModalShow19(false)}/>
        </Card.Body>
		</Card>
		</div>

    


	</div>
</div>
</Container>
</div>
        )
    }

 

// ReactDOM.render(<DemoCarousel />, document.querySelector('.demo-carousel'));
export default Lightingslider;

