import React from 'react';
import {Row, Col, Image} from 'react-bootstrap'
import city1 from '../assets/img/city1.png'
import city4 from '../assets/img/city4.png'
import city6 from '../assets/img/city6.png'
import city from '../assets/img/city.jpg'
import sc1 from '../assets/img/sc1.svg'
import sc2 from '../assets/img/sc2.svg'
import sc3 from '../assets/img/sc3.svg'
import sc4 from '../assets/img/sc4.svg'
import sc5 from '../assets/img/sc5.svg'
import sc6 from '../assets/img/sc6.svg'
import sc7 from '../assets/img/sc7.svg'
import sc8 from '../assets/img/sc8.svg'
import sc9 from '../assets/img/sc9.svg'
import sc10 from '../assets/img/sc10.svg'
import sc11 from '../assets/img/sc11.svg'
import sc12 from '../assets/img/sc12.svg'

const Experiencepage = () => {
    return(
        <div>
        <Row>
        
            <Col xs={12} md={12} className="mt-5 mb-4">
            <h2>Smart City Management</h2>
            </Col>
             <Col xs={12} md={10}>
            <h3 className="blue-themetext">Connected, Optimized, Serviced</h3>
           
            </Col>
            
        </Row>
        <Row className="mt-5">
           <Col  xs={12} md={6}>
               <p>Municipalities are increasingly turning to technology to turbocharge their ability to manage and optimize their cities’ day to day functioning. Our solution driven platform and systems equip the municipalities to save energy, water and time, while keeping an eye on street safety, air quality and traffic. Urban bodies and authorities can take data-driven decisions for the districts. They can relay instant responses for the city.
                </p>
           </Col>
           <Col  xs={12} md={6}>
           <Image src={city} alt="Card image" className="mb-4" fluid/>
           </Col>
           </Row>
        <Row className="mt-5">
        <Col  xs={12} md={12}>
        <h3 className="blue-themetext mb-4">City App & Portal</h3>
        <h5>Stakeholders</h5>
        </Col>
           <Col  xs={12} md={12}>
           <Image src={city1} alt="Card image" className="mb-4 mt-4" fluid/>
           </Col>
         </Row>

         <h3 className="blue-themetext mt-5 mb-4">Capabilities</h3>
           <Row className="text-center mt-5">
           <Col  xs={12} md={3}>
           <Image src={sc1} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Connect with Citizens</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={sc2} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Grievance Redressal (CRM)</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={sc3} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Monitor On-ground Implementation</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={sc4} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Project Management & Tracking on a District Level</p>
           </Col>

           <Col  xs={12} md={3}>
           <Image src={sc5} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Municipality Control Center</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={sc6} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Optimize Project Efficiency</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={sc7} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Conduct Accuracy Site Surveys</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={sc8} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Monitor Air Pollution</p>
           </Col>

           <Col  xs={12} md={3}>
           <Image src={sc9} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Gather Video Surveillance</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={sc10} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Gather Weather Data</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={sc11} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Manage Traffic</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={sc12} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Implement Public Wifi</p>
           </Col>
           
          </Row>

          <Row className="mt-5 mb-5">
            <Col  xs={12} md={12}>
            <h3 className="blue-themetext mb-4">Control & Command Centers</h3>
            <h5 className="mb-4"> Smart Sensors & Controllers</h5>
            </Col>
           <Col  xs={12} md={12}>
           <p>We build and operate smart, high tech sensors and controllers that give us accurate information and optimize performance of the city’s infrastructure. We build customized solutions as per each project’s and city’s needs. </p>
       
           <Image src={city4} alt="Card image" className="mb-4 mt-4" fluid/>
           </Col>
           <Col  xs={12} md={12}>
            <h5 className="mb-4 mt-5">Maintenance and Support</h5>
            </Col>
           <Col  xs={12} md={12}>
           <p>We provide extensive support to the citizens and the municipalities through field assistance and a detailed dashboard. With the city at your fingertips with the city dashboard, we help you make data driven decisions. We operate call centers, and field support offices as needed. </p>
       
           <Image src={city6} alt="Card image" className="mb-4 mt-4" fluid/>
           </Col>
         </Row>

          
           
     </div>
    )
}

export default Experiencepage;