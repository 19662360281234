import React from 'react';
import Contactpage from '../components/Contactpage';
import Container from 'react-bootstrap/Container'

function Contact() {
  return (
    <Container>
      <Contactpage />
      </Container>
      
    
  );
}

export default Contact;