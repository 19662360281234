import React from 'react';
import {Row, Col, Image} from 'react-bootstrap'
import './Approvalpage.css'
import arrow from '../assets/img/arrow.svg'

const Approvalpage = () => {
    return(
        <div>
        <Row>
            <Col xs={12} md={12} className="mt-5 mb-5">
            <h2>Approval</h2>
            </Col>
            </Row>
            <Row id="approvals" className="mb-4">
             <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Tata Projects Limited</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Hq. Chief Engineer, (Mes), Lucknow</h5>
            <div className="line"></div>
            </Col>

            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Power Grid Corporation Of India Limited</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Hq. Chief Engineer, Lucknow Zone</h5>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">B.S.N.L., Janpath, New Delhi</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Hq. Chief Engineer, R&D, (Mes), Probyn Road, Delhi</h5>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Bharat Heavy Electricals Limited, Noida</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Hq. Chief Engineer, (Af), Wac, Palam, Delhi Cantt</h5>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Bharat Heavy Electricals Limited, Bhopal</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Chief Engineer (Af), (Mes), Allahabad</h5>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">C.E. (E) Ndr, C.P.W.D., Vidyut Bhawan, New Delhi</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Hq. Chief Engineer, (Af), Allahabad</h5>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">S. E. (E), Hcec, Cpwd, Hyderabad</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Hq. Chief Engineer, Delhi Zone, Delhi Cantt</h5>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">C.E. (E), Nz, C.P.W.D., R. K. Puram, New Delhi</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Hq. Chief Engineer, Chandigarh</h5>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">C.E. (E), D.R. C.P.W.D., Vidyut Bhawan, New Delhi</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Hq. Chief Engineer, Pathankot Zone, Pathankot</h5>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Directorate Of Design, Engineer-in-chief, New Delhi</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Hq. Chief Engineer, (Mes), Jalandhar Cantt</h5>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Lucknow Development Authority, Lucknow</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Navodaya Vidyalaya Samiti, New Delhi</h5>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Public Works Department, Bhopal</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Consulting Engineering Services (India) Pvt. Ltd., New Delhi</h5>
            <div className="line"></div>
            </Col>
           
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Mpmkvvcl, Bhopal</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Dalal Mott Mac Donald (P) Ltd., Noida</h5>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Army Welfare Housing Organization, Lucknow Cantt</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Gujarat Urja Vikas Nigam Ltd., Baroda</h5>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Map Phase ii & Phase Iii, New Delhi</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">National Thermal Power Corporation, Rihand (Sonebhadra)</h5>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Chief Engineer (Af), (Mes), Ahmedabad</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Damodar Valley Corporation, Kolkata</h5>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Hq. Chief Engineer, (Af) Wac, C/O 56 Apo</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Projects & Development India Ltd., Noida</h5>
            <div className="line"></div>
            </Col>


            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Hq. Chief Engineer, (Mes), Pune</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Rail Coach Factory, Kapurthala</h5>
            <div className="line"></div>
            </Col>


            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Hq. Chief Engineer, (Mes), Bareilly Cantt</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Public Works Department, Raipur (C.G.) (Xlpe Cable)</h5>
            <div className="line"></div>
            </Col>


            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Hq. Chief Engineer, (Mes), Jabalpur</h5>
             <div className="line"></div>
            </Col>
            
            <Col xs={12} md={6}>
            <h5 className="blue-themetext mb-4">Public Works Department, Raipur (C.G.) (Pvc Cable)</h5>
            <div className="line"></div>
            </Col>
            
            
        </Row>

       
        
     </div>
    )
}

export default Approvalpage;