import React from 'react';
import {Row, Col, Container} from 'react-bootstrap'

const Privacy = () => {
    return(
        <Container>
        <Row>
             <Col xs={12} md={12}>
            <h2 className="mt-5 mb-5 ">Privacy Policy</h2>
            </Col>
            <Col xs={12} md={12} className="mb-5 ">
              <p >Elektron Group respects the privacy of our website visitors and we are committed to maintain the privacy and security of every small personal information of visitors to our website.</p>
              <p >
Our policy on the collection and use of personal information and other information is outlined below.</p>

<p >In case of visiting this website to read or download information, it must be known that Elektron Group collects and stores a standard set of internet-related information, such as an Internet Protocol (IP) address, the date and time, the type of browser and operating system used, the pages(s) visited. All information is collected to help Elektron make this site more useful to its customers and only used for statistical purposes. Data collected is not used for purposes other than internal decisions. </p>

<p >We collect name, email address and phone number to be able to respond to queries and help with customer service requests.</p>

<p >Except as set out in this privacy policy, we will not disclose any personally identifiable information without permission, unless Elektron is legally entitled or required to do so or if Elektron believes that it is necessary to protect and/or defend it’s rights, property or personal safety etc.</p>
<p >

Change of Privacy Policy<br/>
Elektron Group reserves the full rights to change/alter/amend/modify the contents of the privacy policy from time to time without any prior notice of intimation.
</p>
            </Col>
        </Row>
        </Container>
    )
}

export default Privacy;