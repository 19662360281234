import React from 'react';
import {Link} from 'react-router-dom';
import './Menu.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import logo from '../assets/img/elektronlogo.png';


const Menu = () => {
  return (
      <Navbar collapseOnSelect className="shadow-sm" bg="white" expand="lg" sticky="top">
      <Navbar.Brand><Link to="/"><img  width="40%" src={logo} alt="..." /></Link></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse className="justify-content-end" activekey="/home" id="responsive-navbar-nav">
        <Nav>
       
        <NavDropdown title="About" id="responsive-navbar-nav">
            <NavDropdown.Item>  <Nav.Link eventKey="1" as={Link} to="Company-profile">Company's Profile</Nav.Link></NavDropdown.Item>
            <NavDropdown.Item> <Nav.Link eventKey="1" as={Link} to="MD-message">MD's Message</Nav.Link></NavDropdown.Item>
            <NavDropdown.Item> <Nav.Link eventKey="1" as={Link} to="Key-People">Key People</Nav.Link></NavDropdown.Item>
            <NavDropdown.Item> <Nav.Link eventKey="1" as={Link} to="Our-journey">Our Journey</Nav.Link></NavDropdown.Item>
            <NavDropdown.Item> <Nav.Link eventKey="1" as={Link} to="Quality-assurance">Quality Assurance</Nav.Link></NavDropdown.Item>
            <NavDropdown.Item> <Nav.Link eventKey="1" as={Link} to="CSR">CSR</Nav.Link></NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Industries" id="responsive-navbar-nav">
            <NavDropdown.Item> <Nav.Link eventKey="1" as={Link} to="Power">Power</Nav.Link></NavDropdown.Item>
            <NavDropdown.Item> <Nav.Link eventKey="1" as={Link} to="Energy">Energy</Nav.Link></NavDropdown.Item>
            <NavDropdown.Item> <Nav.Link eventKey="1" as={Link} to="Technology">Technology</Nav.Link></NavDropdown.Item>
            {/* <NavDropdown.Divider /> */}
          </NavDropdown>
        <NavDropdown title="Solutions" id="responsive-navbar-nav">
            <NavDropdown.Item> <Nav.Link eventKey="1" as={Link} to="Cables-and-Wires">Wires & Cables</Nav.Link></NavDropdown.Item>
            <NavDropdown.Item> <Nav.Link eventKey="1" as={Link} to="Lighting">Lighting</Nav.Link></NavDropdown.Item>
            <NavDropdown.Item> <Nav.Link eventKey="1" as={Link} to="EPC">EPC</Nav.Link></NavDropdown.Item>
            <NavDropdown.Item> <Nav.Link eventKey="1" as={Link} to="Smart-City-Management">Smart City Management</Nav.Link></NavDropdown.Item>
            <NavDropdown.Item> <Nav.Link eventKey="1" as={Link} to="IOT">IOT</Nav.Link></NavDropdown.Item>
            <NavDropdown.Item> <Nav.Link eventKey="1" as={Link} to="Energy-Efficiency">Energy Efficiency</Nav.Link></NavDropdown.Item>
          </NavDropdown>
          <Nav.Link eventKey="2" as={Link} to="360-Experience">360°Experience</Nav.Link>
          <NavDropdown title="Portfolio" id="responsive-navbar-nav">
            <NavDropdown.Item> <Nav.Link eventKey="1" as={Link} to="Clients">Clients</Nav.Link></NavDropdown.Item>
      
          </NavDropdown>
          <NavDropdown title="Contact" id="responsive-navbar-nav">
            <NavDropdown.Item> <Nav.Link eventKey="1" as={Link} to="career">Career</Nav.Link></NavDropdown.Item>
            <NavDropdown.Item> <Nav.Link eventKey="1" as={Link} to="Contact">Get in Touch</Nav.Link></NavDropdown.Item>
          </NavDropdown>
          
        </Nav>
       
        
      </Navbar.Collapse>
    </Navbar>
  )
}


export default Menu;