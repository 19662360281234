import React from 'react';
import {Row, Col, Image} from 'react-bootstrap'
import isi from '../assets/img/isi.png'

const Energypage = () => {
    return(
        <div>
           <Row>
            <Col xs={12} md={12} className="mt-4 mb-4">
            <h2>Energy </h2>
            </Col>
             <Col xs={12} md={12}>
            <h3 className="blue-themetext mt-4">Smart and Sustainable</h3>
            <h4 className="mt-4">LED</h4>
            </Col>
            </Row>
            <Row>
            <Col xs={12} md={6}>
            <p className="mt-4">Elektron Lighting Systems was launched in the year 2012 for manufacturing of LED Lights with a specialized focus on the Street Lights & Outdoor Lighting segment, in alignment with India’s mission to become energy efficient. Specialised customised LED products were developed for hot & humid weather of India, to ensure optimal performance irrespective of external conditions. Their cutting edge R&D supported our inhouse team to deliver truly intelligent and energy efficient products.</p>
            <p className="mt-4">
Elektron LED passed the stringent and extensive testing and was awarded as the World’s First ISI Marked LED. Till date Elektron remains the only LED product to have attempted and passed the tough parameters, no other company or manufacturer has ever established the performance capabilities. </p>
            </Col>
            <Col xs={12} md={6} className="align-self-center text-center">
            <Image src={isi} alt="Card image" fluid />
            </Col>
            </Row>
           
           
     </div>
    )
}

export default Energypage;