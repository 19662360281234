import React from 'react';

import {Link} from 'react-router-dom';
import {Row, Col, Image, Button} from 'react-bootstrap'
import techhead from '../assets/img/techhead.jpg'
import tech1 from '../assets/img/tech1.svg'
import tech2 from '../assets/img/tech2.svg'
import tech3 from '../assets/img/tech3.svg'
import techb1 from '../assets/img/techb1.jpg'
import techb2 from '../assets/img/techb2.jpg'
import techb3 from '../assets/img/techb3.jpg'
import techg1 from '../assets/img/techg1.svg'
import techg2 from '../assets/img/techg2.svg'
import techg3 from '../assets/img/techg3.svg'
import techg4 from '../assets/img/techg4.svg'
import techg5 from '../assets/img/techg5.svg'
import techg6 from '../assets/img/techg6.svg'

const Technologypage = () => {
    return(
        <div>
        <Row>
            <Col xs={12} md={12} className="mt-4 mb-4">
            <h2>Technology</h2>
            </Col>
             <Col xs={12} md={12}>
            <h3 className="blue-themetext mt-4">Reimagining Intelligence and Efficiency Across Sectors</h3>
           
                <Row className="text-center mt-5 mb-5">
                <Col  xs={12} md={4}>
                <Image src={tech1} alt="Card image" className="mt-4" fluid/>
                <p className="blue-themetext">Innovative</p>
                </Col>
                <Col  xs={12} md={4}>
                <Image src={tech2} alt="Card image" className="mt-4" fluid/>
                <p className="blue-themetext">Solution Driven</p>
                </Col>
                <Col  xs={12} md={4}>
                <Image src={tech3} alt="Card image" className="mt-4" fluid/>
                <p className="blue-themetext">Secure</p>
                </Col>
                </Row>
            </Col>
            <Col xs={12} md={12} className="mb-5">
            <Image src={techhead} alt="Card image" fluid />
            </Col>
        </Row>
        <h3 className="blue-themetext mt-5 mb-4">Smart City Management Solution</h3>
        <Row className="mt-4 mb-5">
          <Col  xs={12} md={6}>
           <p>A Smart City is Efficient, Responsive & Safe and these critical objectives are addressed through our City Management Systems. Online monitoring systems are developed for control & management of each such asset from even a mobile app. The complaints management systems allow all the citizens to lodge online complaints and keep a tab on the progress up to its resolution.The entire team involved in city management is connected through a responsive management system with data on cloud making multi way communication effective perse all the tiers right from the lineman to Project Head.  
</p>
           <Button className="button"><Link to="Smart-City-Management">READ MORE</Link></Button>
           </Col>
           <Col  xs={12} md={6}>
           <Image src={techb3} alt="Card image" className="mt-4" fluid/>
           </Col>
         </Row>
         <h3 className="blue-themetext mt-5 mb-4">Internet of Things</h3>
         <Row  className="mt-4 mb-5">
          <Col  xs={12} md={6}>
           <p>The internet has changed our world and IoT is the present revolution in progress with 40 billion connected devices projected by the end of 2021. We interact with computers & mobiles while we issue one directional commands to all other devices. The ability of devices to respond back to us through IoT enabled interfaces brings endless possibilities to life. Elektron has made many such interfaces to connect electrical appliances like Air Conditioners, Room Heaters, Geysers, Power Plugs, Electricity Mains Control in the domestic segment and products like Motors, Drives, Counters etc. in the industrial domain, that are managed through customised mobile & server end applications. From conceptualisation to design & manufacturing of its hardware to deployment in service, all activities are executed in-house by Elektron to ensure performance without disclaimers for 100% customer satisfaction.
</p>
           <Button className="button"><Link to="IOT">READ MORE</Link></Button>
           </Col>
           <Col  xs={12} md={6}>
           <Image src={techb2} alt="Card image" className="mt-4" fluid/>
           </Col>
         </Row>
         <h3 className="blue-themetext mt-5 mb-4">Energy Efficiency</h3>
         <Row  className="mt-4 mb-5">
          <Col  xs={12} md={6}>
           <p>The understanding of technologies & IoT has enabled Elektron to foray into making effective use of it’s skills to crystallise Efficiency in real. Devices & processes functioning with unified goals in tandem can be designed to yield savings in consumption of resources like Energy, Water & Time. Our resolve to achieve efficiencies for our customers in any field we operate has been our motivation to keep pursuing and bringing to market better performing super efficient products & systems.  </p>
           <Button className="button"><Link to="Energy-Efficiency">READ MORE</Link></Button>
           </Col>
           <Col  xs={12} md={6}>
           <Image src={techb1} alt="Card image" className="mt-4" fluid/>
           </Col>
         </Row>
        <h3  className="blue-themetext mt-5 mb-4">Impact Through Disruption</h3>
         <Row className="mt-4 mb-5">
         <Col  xs={12} md={4} className="text-center">
           <Image src={techg1} alt="Card image" className="mt-4" fluid/>
           </Col>
           <Col  xs={12} md={8}>
           <h3  className="blue-themetext mt-4 mb-4"> Agriculture</h3>
           <p>India’s 60% population is dedicated to agriculture. Elektron understands the needs of a farmer to produce higher yield using lower resources. Simple inputs like Soil moisture & nitrogen content if made available at the right time, farming outputs can not only become efficient but reliable. Use of cameras in fields to capture health of agriculture produce alongside other inputs from soil using ARM architecture to develop low energy consuming device interfaces is the challenge Elektron is working on to resolve on platforms of NBIoT & LoRaWan. Simple solutions for simple problems are most difficult to achieve and we are on it. </p>
           </Col>
         </Row>
         <Row className="mt-4 mb-5">
         <Col  xs={12} md={4} className="text-center">
           <Image src={techg2} alt="Card image" className="mt-4" fluid/>
           </Col>
           <Col  xs={12} md={8}>
           <h3  className="blue-themetext mt-4 mb-4"> Security</h3>
           <p>Devices talking to each other & us paves the way for development of endless possibilities in the field of public and private security. Elektron is committed to make available low cost domestic & industrial solutions for video, access, proximity, identification using multiple technologies for a seamless user experience. AI in turn helps us facilitate appropriate responses to diverse situations.
</p>
           </Col>
         </Row>
         <Row className="mt-4 mb-5">
         <Col  xs={12} md={4} className="text-center">
           <Image src={techg3} alt="Card image" className="mt-4" fluid/>
           </Col>
           <Col  xs={12} md={8}>
           <h3  className="blue-themetext mt-4 mb-4">Electric Vehicles</h3>
           <p>The Electric Vehicles require fine tuned hardware with suitable firmware for battery charging and motor control. The heart of EV is the Motor and team Elektron is working to make available low cost efficient reliable interfaces for all types EVs as part of India’s aggressive targets to deploy Electric Vehicles in this decade. </p>
           </Col>
         </Row>
         <Row className="mt-4 mb-5">
         <Col  xs={12} md={4} className="text-center">
           <Image src={techg4} alt="Card image" className="mt-4" fluid/>
           </Col>
           <Col  xs={12} md={8}>
           <h3  className="blue-themetext mt-4 mb-4">Healthcare</h3>
           <p>Six decades of experience in manufacturing has helped us put together the team to conceptualise, design, prototype & manufacture electronic & electrical products of diverse types & applications. We have in-house mfg. capabilities for PCB, SMC, wave soldering, injection moulding, wiring harness, wires & cables, panels, sheet metal fabrication, extrusion, tool rooms, 3D printers backed with the required testing instruments in our ultra-modern laboratories to test, calibrate & evaluate all products. 
</p>
           </Col>
         </Row>
         <Row className="mt-4 mb-5">
         <Col  xs={12} md={4} className="text-center">
           <Image src={techg5} alt="Card image" className="mt-4" fluid/>
           </Col>
           <Col  xs={12} md={8}>
           <h3  className="blue-themetext mt-4 mb-4"> Manufacturing</h3>
           <p>Six decades of experience in manufacturing has helped us put together the team to conceptualise, design, prototype & manufacture electronic & electrical products of diverse types & applications. We have in-house mfg. capabilities for PCB, SMC, wave soldering, injection moulding, wiring harness, wires & cables, panels, sheet metal fabrication, extrusion, tool rooms, 3D printers backed with the required testing instruments in our ultra-modern laboratories to test, calibrate & evaluate all products. </p>
           </Col>
         </Row>
         <Row className="mt-4 mb-5">
         <Col  xs={12} md={4} className="text-center">
           <Image src={techg6} alt="Card image" className="mt-4" fluid/>
           </Col>
           <Col  xs={12} md={8}>
           <h3  className="blue-themetext mt-4 mb-4"> Smart Home</h3>
           <p>We are reimagining homes, with greater convenience, safety and efficiency. Our devices can make traditional, old appliances smarter and efficient, to help you save time as well as on your monthly Electricity and Water bills. Our devices enable your home to cater to your loved ones, and those in need – like the elderly and children – allowing you to live and work stress free. </p>
           </Col>
         </Row>
         <Row  className="text-center mt-5 mb-5">
            <Col>
            <h5>Learn more about our process  <Button className="button m-2"><Link to="360-Experience">360° Experience</Link></Button></h5>
            </Col>
            </Row>
           
     </div>
    )
}

export default Technologypage;