import React from 'react';
import Iotpage from '../components/Iotpage';
import Container from 'react-bootstrap/Container'

function Iot() {
  return (
      <div>
    <Container>
      <Iotpage />
      </Container>
       
    </div>
  );
}

export default Iot;