import React from 'react';
import Careerfirstsection from '../components/Careerfirstsection';
import Container from 'react-bootstrap/Container'
import Currentopening from '../components/Currentopening';
import Internships from '../components/Internships';

function Career() {
  return (
    <Container>
      <Careerfirstsection />
      <Currentopening />
      <Internships />
      </Container>
      
    
  );
}

export default Career;