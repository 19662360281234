import React from 'react';
import {Row, Col, Image} from 'react-bootstrap'
import './Guidelinespage.css'
import arrow from '../assets/img/arrow.svg'

const Guidelinespage = () => {
    return(
        <div>
        <Row>
            <Col xs={12} md={12} className="mt-5 mb-5">
            <h1>Technical Guidelines</h1>
            </Col>
            </Row>
        <Row id="guidelines" className="mb-4">
             <Col xs={12} md={6}>
            <h6 className=" mb-4">Guidelines for High Mast</h6>
            </Col>
            <Col xs={12} md={2} className="text-right">
            <a href={process.env.PUBLIC_URL+"/TechnicalGuidelines/Guidelines-For-High-Mast.pdf"} target='_blank' rel="noopener noreferrer">VIEW DETAILS  <Image src={arrow} alt="Card image" fluid /></a>
            </Col>
            <Col xs={12} md={8}>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h6 className=" mb-4">Guidelines for Street Lighting</h6>
            </Col>
            <Col xs={12} md={2} className="text-right">
            <a href={process.env.PUBLIC_URL+"/TechnicalGuidelines/Guidelines-For-Street-Lights.pdf"} target='_blank' rel="noopener noreferrer">VIEW DETAILS  <Image src={arrow} alt="Card image" fluid /></a>
            </Col>
            <Col xs={12} md={8}>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h6 className=" mb-4">National Lighting Code Presentation</h6>
            </Col>
            <Col xs={12} md={2} className="text-right">
            <a href={process.env.PUBLIC_URL+"/TechnicalGuidelines/NationalLightingCode.pptx"} target='_blank' rel="noopener noreferrer">VIEW DETAILS <Image src={arrow} alt="Card image" fluid /></a>
            </Col>
            
        </Row>
        
     </div>
    )
}

export default Guidelinespage;