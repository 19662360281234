import React from 'react';
import {Row, Col, Image} from 'react-bootstrap'
// import img1 from '../assets/img/3.jpg'

const Careerfirstsection = () => {
    return(
        <div>
        <Row>
            <Col xs={12} md={12} className="mt-5 mb-5">
            <h1>Careers</h1>
            </Col>
             <Col xs={12} md={10}>
            <h2 className="blue-themetext mb-4">Company Culture</h2>
            <p >Our policies and practices are focused on employee well-being as we strive to create a comfortable work environment. We promote diversity and equality at the workplace and strongly believe in providing an equitable work environment to every employee irrespective of race, religion, gender, etc. We endeavour to foster a culture of openness and camaraderie that reinforces transparency and ownership. We are committed to nurturing and grooming our team’s individual talents and help them grow into the organisation’s key leadership roles.</p>
            </Col>
            {/* <Col xs={12} md={4}>
            <Image src={img1} alt="Card image" fluid />
            </Col> */}
        </Row>
         <Row>
         <Col xs={12} md={12} className="mt-5 mb-5">
         <h2 className="blue-themetext">Values</h2>
         </Col>
          <Col xs={12} md={3}>
         <h3 className=" mb-4">01. Honesty</h3>
         </Col>
         <Col xs={12} md={7}>
         <p>We encourage utmost honesty at the workplace to foster a sense of trust among employees, the company and the community.</p>
         </Col>
         <Col xs={12} md={3}>
         <h3 className=" mb-4">02. Transparency</h3>
         </Col>
         <Col xs={12} md={7}>
         <p >We are committed to transparency and accountability at all times. Ownership and commitment enable individuals and teams to work better together.</p>
         </Col>
         <Col xs={12} md={3}>
         <h3 className=" mb-4">03. Innovation</h3>
         </Col>
         <Col xs={12} md={7}>
         <p >At our core, we are a group of passionate and enthusiastic technologists, dedicated to delivering cutting edge solutions. Think big, and beyond the ordinary. </p>
         </Col>
     </Row>
     </div>
    )
}

export default Careerfirstsection;