import React from 'react';
import {Row, Col, Image} from 'react-bootstrap'
import csrimg from '../assets/img/csrimg.jpg'
import csr1 from '../assets/img/csr1.svg'
import csr2 from '../assets/img/csr2.svg'
import csr3 from '../assets/img/csr3.svg'
import csr4 from '../assets/img/csr4.svg'
import csr5 from '../assets/img/csr5.svg'

const Csrpage = () => {
    return(
        <div>
        <Row>
             <Col xs={12} md={12} className="mt-4 mb-4">
            <h1>CSR</h1>
            </Col>
             <Col xs={12} md={12}>
             <Image src={csrimg} alt="Card image" className="mb-4 mt-4" fluid />
            <p>At Elektron, we are committed to sustainable growth and there is hardly any difference in our approach towards work or the CSR philosophy. We all have a role to play in our communities both as individuals and as organisations. We are committed to make a difference to mankind by involvement of all our stakeholders. We have a responsibility towards society which is our very basis of existence. Society trusts us and we need to demonstrate the same trust in return. We, at Elektron, are passionate about a community driven, sustainable and equitable future.</p>
            </Col>
        </Row>
        <Row className="m-0 pt-4 pb-4 text-center">
           <Col>
           <Image src={csr1} alt="Card image" className="mb-4 mt-4" fluid/>
            <p className="blue-themetext">Meals Services</p>
           </Col>
           <Col>
           <Image src={csr2} alt="Card image" className="mb-4 mt-4" fluid/>
               <p className="blue-themetext">Green Energy Projects</p>
           </Col>
           <Col>
           <Image src={csr3} alt="Card image" className="mb-4 mt-4" fluid/>
               <p className="blue-themetext">Supporting Sports Education</p>
           </Col>
           <Col>
           <Image src={csr4} alt="Card image" className="mb-4 mt-4" fluid/>
               <p className="blue-themetext">Women's Safety Initiatives</p>
               
           </Col>
           <Col>
           <Image src={csr5} alt="Card image" className="mb-4 mt-4" fluid/>
               <p className="blue-themetext">Temple Trust</p>
           </Col>
                
          </Row>
         <Row className="mt-4 mb-5">
         <Col xs={12} md={12}>
            <p>We strive to help those all around us to the best of our capabilities. Our labour is a huge part of our success and growth. Their well being is of utmost importance to us. We run a community kitchen at each of our units to ensure they get nutritious daily meals. We have continued to provide food throughout crisis situations like Covid-19 and natural disasters. We are able to leverage our kitchens to serve meals to the children from the schools around us. We are passionate about sustainability and help government bodies set up numerous initiatives as well as actively raise greater awareness for greener practices throughout the industry. We provide our high performing outdoor LED products to Sports Stadiums to ensure our country’s youth continues to play sports and is capable of accessing facilities at all times. Lack of proper lighting in public spaces can directly lead to very unsafe spaces. Women are most impacted in the situation. As a women’s safety we have worked with local authorities to light up key areas to ensure there is plenty of visibility and comfort. We continue to design innovative initiatives that help women feel safer and more protected in the country. We believe in giving back to our community that has supported us. We have built and established a temple trust with Balaji’s blessings that continues to serve all. The trust supports educational initiatives for girls.</p>
            </Col>
         </Row>
     </div>
    )
}

export default Csrpage;