import React from 'react';
import {Row, Col, Image} from 'react-bootstrap'
import quality from '../assets/img/quality.jpg'
import quality1 from '../assets/img/quality1.jpg'
import quality2 from '../assets/img/quality2.png'
import qa1 from '../assets/img/qa1.svg'
import qa2 from '../assets/img/qa2.svg'
import qa3 from '../assets/img/qa3.svg'
import qa4 from '../assets/img/qa4.svg'
import qa5 from '../assets/img/qa5.svg'
import qa6 from '../assets/img/qa6.svg'
import qa7 from '../assets/img/qa7.svg'
import qa8 from '../assets/img/qa8.svg'
import qa9 from '../assets/img/qa9.svg'
import qa10 from '../assets/img/qa10.svg'
import qa11 from '../assets/img/qa11.svg'
import qa12 from '../assets/img/qa12.svg'

const Powerfirstsection = () => {
    return(
        <div>
        <Row>
             <Col xs={12} md={12} className="mt-4 mb-4">
            <h1>Quality Assurance</h1>
            </Col>
            <Col xs={12} md={12} className="mt-4 mb-4">
            <h2 className="blue-themetext">Constantly Strategizing, Innovating and Engineering</h2>
            </Col>
             <Col xs={12} md={12}>
             <Image src={quality} alt="Card image" className="mb-4 mt-4" fluid />
            <p className="ptext">Our specialty lies in delivering high quality and engineered products capable of performing in extreme environments. We engineer and manufacture wires and cables, LEDs and smart hardware solutions,  that consistently outlast and outperform the competition. Our unique capabilities include custom product innovation and development, cutting-edge polymer science, in-house irradiation treatments and manufacturing, which equip us to deliver products of superior quality, durability and flexibility. Our team of engineers have robust knowledge of energy efficiency and are capable of producing highly energy efficient and sustainable products. </p>
            </Col>
        </Row>
        <Row>
         <Col xs={12} md={12} className="mt-5 ">
         <h2 className="blue-themetext">Manufacturing Capabilities</h2>
         </Col>
        </Row>

        <Row className="text-center mt-5">
           <Col  xs={12} md={3}>
           <Image src={qa1} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">PCB Assembly</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={qa2} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Product Assembly</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={qa3} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">3D printers</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={qa4} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">SMT</p>
           </Col>

           <Col  xs={12} md={3}>
           <Image src={qa5} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Wave Soldering</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={qa6} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Injection Moulding</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={qa7} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Wires Harness</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={qa8} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Wires & Cables</p>
           </Col>

           <Col  xs={12} md={3}>
           <Image src={qa9} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Panels</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={qa10} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Sheet Metal Fabrication</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={qa11} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Extrusion</p>
           </Col>
           <Col  xs={12} md={3}>
           <Image src={qa12} alt="Card image" className="mb-2 mt-4" fluid/>
           <p className="blue-themetext">Tool Rooms</p>
           </Col>
           
          </Row>

         <Row>
         <Col xs={12} md={12} className="mt-5 mb-5">
         <h2 className="blue-themetext">Research & Development</h2>
         </Col>
         <Col xs={12} md={8}>
            <p>Our research and development capabilities, emphasis on upgrading the technology used in our production process, customer-centric R&D efforts and our R&D center located in Haryana and Aurangabad, assist our sales and marketing team in understanding our customers’ requirements. We are capable of truly innovating and disrupting by channelizing our engineering and software capabilities.</p>
            </Col>
         <Col xs={12} md={4} className="mb-4">
         <Image src={quality1} alt="Card image" fluid />
         </Col>
         </Row>
         <Row>
         <Col xs={12} md={12} className="mt-5 mb-5">
         <h2 className="blue-themetext">Quality Promise</h2>
         </Col>
         <Col xs={12} md={8}>
            <p>Elektron believes in always providing quality to our customers. We recognise that our suppliers play a key part in the success of our business. Our network of labs and QA engineers, ensure we deliver only the highest quality and long performing products. We develop our supply chains to incorporate the requirements and compliances within their quality management systems.</p>
            </Col>
         <Col xs={12} md={4} className="mb-4 text-center">
         <Image src={quality2} alt="Card image" fluid width="50%"/>
         </Col>
         </Row>
     </div>
    )
}

export default Powerfirstsection;