import React from 'react';
import Smartcitypage from '../components/Smartcitypage';
import Container from 'react-bootstrap/Container'

function Smartcity() {
  return (
      <div>
    <Container>
      <Smartcitypage />
      </Container>
       
    </div>
  );
}

export default Smartcity;