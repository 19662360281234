import React from 'react';
import {Row, Col, Image} from 'react-bootstrap'
import './Downloadspage.css'
import download from '../assets/img/download.svg'

const Downloadspage = () => {
    return(
        <div>
        <Row>
            <Col xs={12} md={12} className="mt-5 mb-5">
            <h1>Downloads</h1>
            </Col>
            </Row>
        <Row id="downloads" className="mb-4">
             <Col xs={12} md={6}>
            <h6 className=" mb-4">ISI Marked LED Module Catalogue</h6>
            </Col>
            <Col xs={12} md={2} className="text-right">
            <a href={process.env.PUBLIC_URL+"/Downloads/ISI_Marked_LED_Module_Catalogue.pdf"} target='_blank' rel="noopener noreferrer">DOWNLOAD  <Image src={download} alt="Card image" fluid /></a>
            </Col>
            <Col xs={12} md={8}>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h6 className=" mb-4">Product Catalogue</h6>
            </Col>
            <Col xs={12} md={2} className="text-right">
            <a href={process.env.PUBLIC_URL+"/Downloads/Product_Catalogue(3-1-15).pdf"} target='_blank' rel="noopener noreferrer">DOWNLOAD  <Image src={download} alt="Card image" fluid /></a>
            </Col>
            <Col xs={12} md={8}>
            <div className="line"></div>
            </Col>

            <Col xs={12} md={6}>
            <h6 className=" mb-4">Price List</h6>
            </Col>
            <Col xs={12} md={2} className="text-right">
            <a href={process.env.PUBLIC_URL+"/Downloads/PriceList.pdf"} target='_blank' rel="noopener noreferrer">DOWNLOAD <Image src={download} alt="Card image" fluid /></a>
            </Col>
            
        </Row>
        
     </div>
    )
}

export default Downloadspage;