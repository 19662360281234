import React from 'react';
import {Row, Col, Image} from 'react-bootstrap'
import imgparagon from '../assets/img/4.jpg'
import cable from '../assets/img/cable.jpg'

const Cablefirst = () => {
    return(
        <div>
        <Row>
            <Col xs={12} md={12} className="mt-4 mb-4">
           <h2>Cables & Wires</h2>
            <h3 className="blue-themetext mt-4">Connect to a Better World</h3>
            </Col>
             <Col xs={12} md={8}>
             <Image src={imgparagon} alt="Card image" className="mb-4" fluid  width="15%"/>
            <p>Paragon is a well established brand of Wires & Cables enjoying pan India presence. We have been manufacturing Electrical Power Cables since 1962 and are proud to be associated as vendors to almost all the Power Distribution Utilities like BSES, NDPL, Powergrid, State owned Utilities like MSEDCL, PSPCL, Generation Utilities as NTPC, BHEL, MAHAGENCO  and many others. In the Private segment our products are regularly used by TATA Projects, Bajaj Electricals, L&T, Siemens, Voltas, Transrail and many renowned companies involved in Power Electrification & Distribution Projects. Our Cables are approved & preferred by PWDs, CPWD, NBCC, MES, Railways, ONGC, GAIL - amongst Government Organisations.
</p>
            </Col>
           
        </Row>
         <Row className="mt-5">
         <Col xs={12} md={12}>
            <Image src={cable} alt="Card image" fluid />
            </Col>
     </Row>
     </div>
    )
}

export default Cablefirst;