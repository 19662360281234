
import React from 'react';
// import logo from './logo.svg';
// import './App.css';
import Home from './Home';
import Career from './Career';
import Power from './Power';
import Clients from './Clients';
import Downloads from './Downloads';
import Guidelines from './Guidelines';
import Approvals from './Approvals';
import Contact from './Contact';
import Qualityassurance from './Qualityassurance';
import Csr from './Csr';
import Mdmessage from './Mdmessage';
import Iot from './Iot';
import Team from './Team';
import Companyprofile from './Companyprofile';
import Ourjourney from './Ourjourney';
import Experience from './Experience';
import Energyefficiency from './Energyefficiency';
import Technology from './Technology';
import Lighting from './Lighting';
import Cable from './Cable';
import Epc from './Epc';
import Smartcity from './Smartcity';
import Energy from './Energy';
import Terms from './Terms';
import Privacy from './Privacy';
// import Menumobile from '../components/Menumobile';
import Menu from '../components/Menu';
import {BrowserRouter, Route, Switch } from 'react-router-dom';
import Footer from './Footer';

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};


function Approuter() {
  return (
    <BrowserRouter>
    <Route component={ScrollToTop} />
    <div>
    {/* <ScrollToTop /> */}
      <Menu />
      <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/career" component= {Career} />
      <Route path="/power" component= {Power} />
      <Route path="/clients" component= {Clients} />
      <Route path="/downloads" component= {Downloads} />
      <Route path="/approvals" component= {Approvals} />
      <Route path="/technical-guidelines" component= {Guidelines} />
      <Route path="/contact" component= {Contact} />
      <Route path="/Quality-assurance" component= {Qualityassurance} />
      <Route path="/CSR" component= {Csr} />
      <Route path="/MD-message" component= {Mdmessage} />
      <Route path="/IOT" component= {Iot} />
      <Route path="/Key-People" component= {Team} />
      <Route path="/Company-profile" component= {Companyprofile} />
      <Route path="/Our-journey" component= {Ourjourney} />
      <Route path="/360-Experience" component= {Experience} />
      <Route path="/Energy-Efficiency" component= {Energyefficiency} />
      <Route path="/Technology" component= {Technology} />
      <Route path="/Lighting" component= {Lighting} />
      <Route path="/Cables-and-Wires" component= {Cable} />
      <Route path="/EPC" component= {Epc} />
      <Route path="/Smart-City-Management" component= {Smartcity} />
      <Route path="/Energy" component= {Energy} />
      <Route path="/Terms-Conditions" component= {Terms} />
      <Route path="/PrivacyPolicy" component= {Privacy} />
      </Switch>
      <Footer />
    </div>
    </BrowserRouter>
  )
}

export default Approuter;
