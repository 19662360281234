import React from 'react';
import Experiencepage from '../components/Experiencepage';
import Experienceprefooter from '../components/Experienceprefooter';
import Container from 'react-bootstrap/Container'

function Experience() {
  return (
      <div>
    <Container>
      <Experiencepage />
      </Container>
       <Experienceprefooter />
    </div>
  );
}

export default Experience;